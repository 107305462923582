import * as React from "react";
import Products from "../components/Products/Products";
import LayoutWithCart from "../components/Layout/LayoutWithCart";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const ProductsPage = () => {
  return (
    <>
      <LayoutWithCart showCart={true}>
        <HeadingBold variant="h4">Products</HeadingBold>
        <Products />
      </LayoutWithCart>
    </>
  );
};
export default ProductsPage;
