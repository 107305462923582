import { useAxios } from "../utils/hooks";
import { Pageable } from "../types/common";
import { Country, CountryIdOnly } from "./CountryService";

export interface BillingAddress extends BillingAddressIdOnly {
  name: string;
  description: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  country: CountryIdOnly | Country | null;
  phone: string;
  email: string;
  vatId: string;
}

export interface BillingAddressIdOnly {
  id?: string;
}

export interface BillingAddressFull extends BillingAddress {
  country: Country | null;
}

interface BillingAddressService {
  getBillingAddresses: (pageable: Pageable) => Promise<BillingAddress[]>;
  getBillingAddressById: (billingAddressId: string) => Promise<BillingAddress>;
  createBillingAddress: (
    billingAddress: BillingAddress
  ) => Promise<BillingAddress>;
  updateBillingAddress: (
    billingAddress: BillingAddress
  ) => Promise<BillingAddress>;
  getFilteredBillingAddresses: (
    inputValue: string,
    pageable: Pageable
  ) => Promise<BillingAddress[]>;
}

export const useBillingService = (): BillingAddressService => {
  const axios = useAxios("");
  return {
    getBillingAddresses: async (pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/address/billing?start=${offset}&limit=${limit}&sort=name`
      );
      return value.data as BillingAddress[];
    },
    getBillingAddressById: async (billingAddressId: string) => {
      const value = await axios.current!.get(
        `/api/address/billing/${billingAddressId}`
      );
      return value.data as BillingAddress;
    },
    createBillingAddress: async (data: BillingAddress) => {
      delete data.id;
      const value = await axios.current!.post("/api/address/billing", data);
      return value.data as BillingAddress;
    },
    updateBillingAddress: async (data: BillingAddress) => {
      const value = await axios.current!.put(
        `/api/address/billing/${data.id}`,
        data
      );

      return value.data as BillingAddress;
    },
    getFilteredBillingAddresses: async (
      inputValue: string,
      pageable: Pageable
    ) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/address/billing?start=${offset}&limit=${limit}&sort=name&search=${inputValue}`
      );

      return value.data as BillingAddress[];
    },
  };
};
