import { useAxios } from "../utils/hooks";
import {
  ShippingAddress,
  ShippingAddressFull,
  ShippingAddressIdOnly,
} from "./ShippingAddressService";
import { Pageable } from "../types/common";
import {
  BillingAddress,
  BillingAddressFull,
  BillingAddressIdOnly,
} from "./BillingAddressService";

export interface OrderDTO {
  id?: string;
  orderNumber?: string;
  orderState: string | null;
  purchaseOrder?: string;
  shippingAddress?: ShippingAddressIdOnly | ShippingAddress | null;
  billingAddress?: BillingAddressIdOnly | BillingAddress | null;
  products?: OrderProductDTO[] | undefined;
  comments?: string;
}

export interface OrderProductDTO {
  productSkuId: string;
  productSkuCode: string;
  productSkuName: string;
  productGroupName: string;
  quantity: number;
  imageUrl: string;
}

export interface OrderFull extends OrderDTO {
  shippingAddress?: ShippingAddressFull | null;
  billingAddress?: BillingAddressFull | null;
}

interface OrderService {
  getOrders: (pageable: Pageable) => Promise<OrderDTO[]>;
  getOrderById: (orderId: string) => Promise<OrderDTO>;
  getFilteredOrders: (
    inputValue: string,
    pageable: Pageable
  ) => Promise<OrderDTO[]>;
  getFilteredOrdersWithState: (
    selectValue: string,
    pageable: Pageable
  ) => Promise<OrderDTO[]>;
  getFilteredOrdersWithStateAndSearch: (
    inputValue: string,
    selectValue: string,
    pageable: Pageable
  ) => Promise<OrderDTO[]>;
}

export const useOrderService = (): OrderService => {
  const axios = useAxios("");
  return {
    getOrders: async (pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/order?start=${offset}&limit=${limit}`
      );
      return value.data as OrderDTO[];
    },
    getOrderById: async (orderId: string) => {
      const value = await axios.current!.get(`/api/order/${orderId}`);
      return value.data as OrderDTO;
    },
    getFilteredOrders: async (inputValue: string, pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/order?start=${offset}&limit=${limit}&search=${inputValue}`
      );

      return value.data as OrderDTO[];
    },
    getFilteredOrdersWithState: async (
      selectValue: string,
      pageable: Pageable
    ) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/order?start=${offset}&limit=${limit}&state=${selectValue}`
      );

      return value.data as OrderDTO[];
    },
    getFilteredOrdersWithStateAndSearch: async (
      inputValue: string,
      selectValue: string,
      pageable: Pageable
    ) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/order?start=${offset}&limit=${limit}&search=${inputValue}&state=${selectValue}`
      );

      return value.data as OrderDTO[];
    },
  };
};
