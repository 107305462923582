import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import StyledStack from "./StyledStack";
import { BillingAddress } from "../../services/BillingAddressService";

interface SearchProps {
  searchTerm: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rows?: BillingAddress[];
}

const SearchAndCreate: React.FC<SearchProps> = ({
  searchTerm,
  handleSearch,
  rows = [],
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("new");
  };

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const labelText = isBigScreen ? "Search (min 3 chars)" : "Search";

  const rowsNotEmpty = rows?.length > 0;
  return (
    <>
      <StyledStack>
        <>
          {isBigScreen ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: "auto" }}
              onClick={handleClick}
              disabled={rowsNotEmpty}
            >
              Create
            </Button>
          ) : (
            <IconButton
              color="primary"
              size="small"
              aria-label="create"
              sx={{ marginLeft: "auto" }}
              onClick={handleClick}
              disabled={rowsNotEmpty}
            >
              <AddIcon />
            </IconButton>
          )}
          <TextField
            label={labelText}
            size="small"
            style={{ width: isBigScreen ? "auto" : "8rem" }}
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search term">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      </StyledStack>
    </>
  );
};

export default SearchAndCreate;
