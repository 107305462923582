import React from "react";
import { useNavigate } from "react-router";
import { StyledLogo } from "./styled/styledMedia";
import logo from "../../assets/logo.png";
import { useTheme } from "@mui/system";
import { Tooltip, useMediaQuery } from "@mui/material";

const HeaderLogo = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/order");
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      {isSmallScreen ? (
        <Tooltip title="Back Home" placement="bottom-start">
          <StyledLogo
            src={logo}
            alt="APS logo."
            onClick={handleClick}
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      ) : (
        <StyledLogo
          src={logo}
          alt="APS logo."
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        />
      )}
    </>
  );
};

export default HeaderLogo;
