import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { useCartContext } from "../../../store/CartProvider";
import TotalAmountPagination from "../../UI/TotalAmountPagination";
import {
  HiddenCellForMobile,
  StyledGridBox,
} from "../../UI/styled/styledTables";
import { StyledCardMedia } from "../../UI/styled/styledMedia";
import { HeadingBold } from "../../UI/styled/styledFonts";
import dummyPicture from "../../../assets/dummyPicture.png";
import {
  CartDTO,
  CartProductDTO,
  useCartService,
} from "../../../services/CartService";

function ReviewOrderTable() {
  const cartCtx = useCartContext();
  const order = cartCtx.order;
  const cartService = useCartService();

  const handleDeleteClick = (productSkuId: string | undefined | number) => {
    const cartProduct: CartProductDTO[] = order?.products!.map((entry) => {
      if (productSkuId === entry.productSkuId) {
        return {
          productSkuId,
          quantity: 0,
        } as CartProductDTO;
      } else {
        return {
          productSkuId: entry.productSkuId,
          quantity: entry.quantity,
        } as CartProductDTO;
      }
    });

    const cart: CartDTO = {
      orderId: order.id!,
      products: cartProduct,
    };

    cartService.updateCart(order.id!, cart).then(() => {
      cartCtx.reload();
    });
  };

  return (
    <>
      <HeadingBold variant="h5">Order Summary</HeadingBold>
      <StyledGridBox as={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <HiddenCellForMobile>Image</HiddenCellForMobile>
              <HiddenCellForMobile>Group</HiddenCellForMobile>
              <TableCell>Model</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>QTY</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.products!.map((product) => {
              return (
                <TableRow key={product.productSkuId}>
                  <HiddenCellForMobile>
                    <StyledCardMedia
                      src={product.imageUrl || dummyPicture}
                      alt={product.productSkuName}
                    />
                  </HiddenCellForMobile>
                  <HiddenCellForMobile>
                    {product.productGroupName
                      ? product.productGroupName
                      : "MISSING PRODUCT GROUP"}
                  </HiddenCellForMobile>
                  <TableCell>
                    {product.productSkuCode
                      ? product.productSkuCode
                      : "MISSING MODEL"}
                  </TableCell>
                  <TableCell>
                    {product.productSkuName ? (
                      product.productSkuName
                    ) : (
                      <Typography variant="inherit" color="error">
                        MISSING DESCRIPTION
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => handleDeleteClick(product.productSkuId!)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TotalAmountPagination />
      </StyledGridBox>
    </>
  );
}

export default ReviewOrderTable;
