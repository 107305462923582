import React from "react";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledGrid } from "../components/UI/styled/styledLayout";
import { HeadingBold, Heading } from "../components/UI/styled/styledFonts";
import { NavLink } from "react-router-dom";
import { useRouteError } from "react-router-dom";
import { useLocation } from "react-router";

export default function ErrorPage() {
  const error: unknown = useRouteError();
  const error2 = useLocation().state?.error;
  const errorMessage2 = useLocation().state?.errorMessage;
  const statusText = (error as { statusText?: string })?.statusText;
  const errorMessage = (error as Error)?.message;
  const status = (error as { status?: number })?.status;
  const data = (error as { data?: string })?.data;

  const title = data || error2 || "Sorry, an unexpected error has occurred.";
  const message = status
    ? status + " " + statusText
    : statusText || errorMessage || errorMessage2;

  const condition = status || statusText || errorMessage || errorMessage2;

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12}>
        <HeadingBold variant="h4">Error</HeadingBold>
        <Heading variant="h5">{title}</Heading>
        {condition && <Heading variant="h6">{message}</Heading>}
      </Grid>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          component={NavLink}
          to="/"
        >
          BACK TO HOME
        </Button>
      </Box>
    </StyledGrid>
  );
}
