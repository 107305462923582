import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

interface SearchProps {
  searchTerm: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchField: React.FC<SearchProps> = ({ searchTerm, handleSearch }) => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const labelText = isBigScreen ? "Search (min 3 chars)" : "Search";

  return (
    <>
      <Box
        sx={{ flexGrow: 1, marginBottom: 3, marginTop: 3 }}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        <TextField
          label={labelText}
          value={searchTerm}
          size="small"
          style={{ width: isBigScreen ? "auto" : "8rem" }}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="search term">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default SearchField;
