import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { ShippingAddress } from "../../services/ShippingAddressService";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";

interface Props {
  shippingAddresses: ShippingAddress[];
}

export const getColumns = ({ shippingAddresses }: Props) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 0.5,
        minWidth: 140,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "addressLine1",
        headerName: "Address Line 1",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "addressLine2",
        headerName: "Address Line 2",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "zip",
        headerName: "Zipcode",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "city",
        headerName: "City",
        flex: 0.2,
        minWidth: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "country",
        headerName: "Country",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.country?.name;
        },
      },
      {
        field: "phone",
        headerName: "Telephone",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 0.5,
        minWidth: 140,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "parentName",
        headerName: "Billing Address",
        flex: 0.5,
        minWidth: 140,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.parentName;
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        sortable: false,
        filterable: false,
        resizable: false,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              size="small"
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
            />,
          ];
        },
      },
    ],
    [shippingAddresses]
  );

  const navigate = useNavigate();

  const handleEditClick =
    (shippingAddressId: string | undefined | number) => async () => {
      if (shippingAddressId) {
        const editedRow = shippingAddresses.find(
          (row) => row.id === shippingAddressId
        );
        if (editedRow) {
          navigate(`/shipping-address/${shippingAddressId}/edit`);
        }
      }
    };

  const hiddenColumns: string[] = [
    "description",
    "addressLine1",
    "addressLine2",
    "zip",
    "country",
    "phone",
    "email",
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const visibleColumns = isSmallScreen
    ? columns.filter((column) => !hiddenColumns.includes(column.field))
    : columns;

  return { visibleColumns };
};
