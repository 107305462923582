import { TableContainer } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import React from "react";
import { BillingAddress } from "../../services/BillingAddressService";
import { OrderDTO } from "../../services/OrderService";
import { ProductSku } from "../../services/ProductService";
import { ShippingAddress } from "../../services/ShippingAddressService";

export const rowsPerPage = 25;

interface Props {
  rows: ProductSku[] | OrderDTO[] | BillingAddress[] | ShippingAddress[];
  columns: GridColDef[];
  rowCount?: number;
  loading: boolean;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (paginationModel: {
    page: number;
    pageSize: number;
  }) => void;
}

const TablePaginator: React.FC<Props> = ({
  rows,
  columns,
  rowCount,
  loading,
  paginationModel,
  onPaginationModelChange,
}) => (
  <TableContainer style={{ width: "100%", height: "auto" }}>
    <DataGrid
      rows={rows}
      columns={columns}
      rowCount={rowCount}
      rowHeight={36}
      loading={loading}
      pageSizeOptions={[rowsPerPage]}
      pagination
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
    />
  </TableContainer>
);

export default TablePaginator;
