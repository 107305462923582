import * as React from "react";
import { useContext, useEffect, useState } from "react";

export interface ServerConfig {
  lastRefreshed: number;
  baseUrl: string;
  apiBaseUrl: string;
  oauthClientId: string;
}

export interface ServerConfigExt extends ServerConfig {
  lastRefreshed: number;
}

export interface ServerConfigContext {
  config?: ServerConfigExt;
}

export const ServerConfigContext = React.createContext<ServerConfigContext>(
  null as any
);
ServerConfigContext.displayName = "ServerConfigContext";

interface Props {
  children: React.ReactNode;
}

function loadStorage(): ServerConfigExt | undefined {
  let item = localStorage.getItem("zemloServerConfig");
  if (item != null) {
    return JSON.parse(item) as ServerConfigExt;
  } else {
    return undefined;
  }
}

export function setStorage(it?: ServerConfigExt): void {
  if (it) {
    localStorage.setItem("zemloServerConfig", JSON.stringify(it));
  } else {
    localStorage.removeItem("zemloServerConfig");
  }
}

async function getConfigFromServer() {
  const respWrapper = await fetch("/config");
  const respBody = await respWrapper.json();
  console.log(respBody);
  return respBody as ServerConfig;
}

// default config, will be overriden by env vars in k8s deployments hence no worries setting it for local dev
const defaultConfig = {
  lastRefreshed: 0,
  baseUrl: "http://localhost:3000",
  apiBaseUrl: "http://localhost:8080",
  oauthClientId: "ynomsfe",
};

export default function ServerConfigProvider({ children }: Props) {
  const storage = loadStorage();
  const [config, setConfig] = useState<ServerConfigExt | undefined>(
    () => storage
  );

  useEffect(() => {
    // refresh after more than 5m since last
    if (
      typeof config === "undefined" ||
      config.lastRefreshed < Date.now() - 300_000
    ) {
      getConfigFromServer()
        .then((it) => {
          const newConfig = Object.assign(it, { lastRefreshed: Date.now() });
          setConfig(newConfig);
          setStorage(newConfig);
        })
        .catch((err) => {
          console.error(
            "Error while fetching config (can be ignored during development): " +
              err
          );
          setConfig(defaultConfig);
          setStorage(defaultConfig);
        });
    }
  }, []);

  return (
    <ServerConfigContext.Provider value={{ config: config }}>
      {children}
    </ServerConfigContext.Provider>
  );
}

export function useServerConfig(): ServerConfig | undefined {
  return useContext(ServerConfigContext)?.config;
}
