import { useAxios } from "../utils/hooks";
import { Pageable } from "../types/common";
import { Product } from "./ProductService";

export interface ProductGroup {
  id: string;
  name: string;
  instanceName: string;
}

interface ProductGroupService {
  getProductGroups: () => Promise<ProductGroup[]>;
  getFilteredProductsWithGroup: (
    selectValue: string,
    pageable: Pageable
  ) => Promise<Product[]>;
}

export const useProductGroupService = (): ProductGroupService => {
  const axios = useAxios("");
  return {
    getProductGroups: async () => {
      const value = await axios.current!.get("/api/product/group");
      return value.data as ProductGroup[];
    },
    getFilteredProductsWithGroup: async (
      selectValue: string,
      pageable: Pageable
    ) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/product?start=${offset}&limit=${limit}&sort=description&group=${selectValue}`
      );

      return value.data as Product[];
    },
  };
};
