import { Stack, styled } from "@mui/material";

export const PrintableDiv = styled("div")({
  "@media print": {
    display: "block",
    padding: "1cm",
    margin: 0,
  },
});

export const StyledStackPrint = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  "@media print": {
    flexDirection: "row",
  },
}));

export const StackAlignLeft = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "left",
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(1),
  },
}));
