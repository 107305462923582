import { Modal, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  ProductSku,
  Product,
  useProductService,
} from "../../services/ProductService";
import { useCartContext } from "../../store/CartProvider";
import Loading from "../UI/Loading";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { BoxPositionAbsolute } from "../UI/styled/styledLayout";
import {
  StyledCloseButton,
  StyledOrderButton,
} from "../UI/styled/styledButtons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ChipStockLabel from "../UI/ChipStockLabel";
import { ProductModalTableContainer } from "../UI/styled/styledTables";
import ProductQuantityField from "./ProductQuantityField";
import ProductModalTable from "./ProductModalTable";
import { useAlert } from "../../store/AlertProvider";
import { useParams } from "react-router-dom";
import {
  CartDTO,
  CartProductDTO,
  useCartService,
} from "../../services/CartService";

interface Props {
  id: string;
  open: boolean;
  onClose: () => void;
}

const ProductsModal = (props: Props) => {
  const [productSkus, setProductSkus] = useState<ProductSku[]>([]);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<string[]>([]);
  const { onClose } = props;
  const { orderId } = useParams<{ orderId: string }>();
  const productService = useProductService();
  const cartService = useCartService();
  const cartCtx = useCartContext();
  const order = cartCtx.order;
  const { showAlert } = useAlert();

  const [selectedQuantities, setSelectedQuantities] = useState<{
    [productSkuId: string]: number;
  }>({});

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "code",
        headerName: "Model",
        flex: 0.5,
        minWidth: 140,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "name",
        headerName: "Description",
        flex: 0.5,
        minWidth: 140,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "stock",
        headerName: "Available Stock",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <ChipStockLabel value={params.row.stock} />
        ),
      },
      {
        field: "qty",
        headerName: "QTY",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        pinnable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <ProductQuantityField
            productSkus={productSkus}
            selectedQuantities={selectedQuantities}
            handleInputChange={handleInputChange}
            rowId={params.row.id}
            rowCode={params.row.code}
            rowStock={params.row.stock}
          />
        ),
      },
    ],
    [productSkus, selectedQuantities]
  );

  const hiddenColumns: string[] = ["name", "stock"];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const visibleColumns = isSmallScreen
    ? columns.filter((column) => !hiddenColumns.includes(column.field))
    : columns;

  useEffect(() => {
    setLoading(true);

    productService
      .getProductById(props.id)
      .then(function (product: Product) {
        const productSkus = product.skus.flat() || [];
        setProductSkus(product.skus);

        for (const i in productSkus) {
          const productSku = productSkus[i];
          const matchingProduct = order.products?.find(
            (product) => product.productSkuId === productSku.id
          );
          const matchingProductQuantity = matchingProduct?.quantity;

          if (matchingProduct && matchingProductQuantity) {
            setSelectedQuantities((prevQuantities) => ({
              ...prevQuantities,
              [productSku.id]: matchingProductQuantity,
            }));
          } else {
            setSelectedQuantities((prevQuantities) => ({
              ...prevQuantities,
              [productSku.id]: 0,
            }));
          }
        }
      })
      .finally(function () {
        setLoading(false);
      });
  }, [order.id, order.products, props.id]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    const cartProducts: CartProductDTO[] = Object.entries(
      selectedQuantities
    ).map(
      (entry) =>
        ({ productSkuId: entry[0], quantity: entry[1] } as CartProductDTO)
    );

    const cart: CartDTO = {
      orderId: order.id!,
      products: cartProducts,
    };

    cartService.updateCart(orderId!, cart).then((response) => {
      cartCtx.reload();
      onClose();

      if (response.notifications && response.notifications.length > 0) {
        setNotifications(response.notifications);
        response.notifications.forEach((notification) => {
          console.log(notification);
          showAlert(`${notification}`, { variant: "warning" });
        });
      }
    });
  };

  const handleInputChange = (productSkuId: string, value: string) => {
    const parsedValue = value === "" ? 0 : parseInt(value, 10);
    setSelectedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productSkuId]: parsedValue,
    }));
  };

  const filteredRows = productSkus.filter((row) => row.stock >= 1);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Modal
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxPositionAbsolute>
            <form onSubmit={submitHandler}>
              {onClose ? (
                <StyledCloseButton aria-label="close" onClick={onClose}>
                  <CloseIcon />
                </StyledCloseButton>
              ) : null}
              <ProductModalTableContainer as={Paper}>
                <ProductModalTable
                  rows={filteredRows}
                  columns={visibleColumns}
                  loading={loading}
                />
              </ProductModalTableContainer>
              <StyledOrderButton
                variant="contained"
                type="submit"
                startIcon={<ShoppingCartIcon />}
                fullWidth
              >
                Add to order
              </StyledOrderButton>
            </form>
          </BoxPositionAbsolute>
        </Modal>
      )}
    </>
  );
};

export default ProductsModal;
