import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useCartContext } from "../../../store/CartProvider";
import { HeadingBold } from "../../UI/styled/styledFonts";
import { StyledGridBox } from "../../UI/styled/styledTables";
import TotalAmountPagination from "../../UI/TotalAmountPagination";

function OrderItemsTable() {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  return (
    <>
      <HeadingBold variant="h5">Order Items</HeadingBold>
      <StyledGridBox as={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>QTY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.products!.map((product) => (
              <TableRow key={product.productSkuId}>
                <TableCell>{product.productSkuCode}</TableCell>
                <TableCell>{product.productSkuName}</TableCell>
                <TableCell>{product.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TotalAmountPagination />
      </StyledGridBox>
    </>
  );
}

export default OrderItemsTable;
