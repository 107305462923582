import { Chip } from "@mui/material";
import React from "react";

interface Props {
  value: number;
}

const ChipStockLabel: React.FC<Props> = ({ value }) => {
  const getStockCategory = (stockValue: number) => {
    if (stockValue < 1) {
      return { className: "OUT OF STOCK", backgroundColor: "grey" };
    } else if (stockValue >= 1 && stockValue <= 5) {
      return { className: "LOW", backgroundColor: "#f44336" };
    } else if (stockValue >= 6 && stockValue <= 10) {
      return {
        className: "MEDIUM",
        backgroundColor: "#ff9800",
      };
    } else if (stockValue >= 11) {
      return { className: "HIGH", backgroundColor: "#4caf50" };
    } else {
      return { className: "DEFAULT", backgroundColor: "grey" };
    }
  };
  const category = getStockCategory(value);

  return (
    <Chip
      size="small"
      label={category!.className}
      style={{
        backgroundColor: category!.backgroundColor,
      }}
    />
  );
};

export default ChipStockLabel;
