import { Card, CardContent, styled } from "@mui/material";

export const StyledCardMedia = styled("img")({
  objectFit: "contain",
  height: "2rem",
  width: "5rem",
});

export const StyledProductCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "20.5rem",
  },
}));

export const StyledProductCardMedia = styled("img")(({ theme }) => ({
  objectFit: "contain",
  height: "12rem",
  width: "100%",
  marginTop: theme.spacing(2),
}));

export const StyledProductCardContent = styled(CardContent)({
  height: "5rem",
  overflow: "hidden",
});

export const StyledLogo = styled("img")(({ theme }) => ({
  objectFit: "contain",
  height: "3rem",
  display: "block",
  [theme.breakpoints.down("sm")]: {
    height: "2rem",
  },
}));
