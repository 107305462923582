import * as React from "react";
import Layout from "../components/Layout/Layout";
import ShippingAddressForm from "../components/ShippingAddress/ShippingAddressForm";

const NewShippingAddressPage = () => {
  return (
    <>
      <Layout>
        <ShippingAddressForm shippingAddress={null} />
      </Layout>
    </>
  );
};
export default NewShippingAddressPage;
