import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { BoldTextLabel } from "./styled/styledFonts";

interface Props {
  title: string;
  content: ReactNode;
}

const BodyText: React.FC<Props> = ({ title, content }) => {
  return (
    <Typography variant="body1" gutterBottom>
      <BoldTextLabel>{title}</BoldTextLabel>
      {content}
    </Typography>
  );
};

export default BodyText;
