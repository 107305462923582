import React from "react";
import { List } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ViewListIcon from "@mui/icons-material/ViewList";
import ContentPaste from "@mui/icons-material/ContentPaste";
import MenuItem, { Item } from "./MenuItem";

const menuItems: Item[] = [
  {
    icon: ContentPaste,
    name: "At Once Order",
    link: "/order/new",
  },
  { icon: ViewListIcon, name: "View Orders", link: "/order" },
  {
    icon: LocalShippingIcon,
    name: "Ship-to Address",
    link: "/shipping-address",
  },
  {
    icon: AccountBalanceWalletIcon,
    name: "Bill-to Address",
    link: "/billing-address",
  },
  { icon: Inventory2Icon, name: "Stock", link: "/stock" },
];

const Menu = () => {
  return (
    <>
      <List component="div">
        {menuItems.map((item, index) => (
          <MenuItem key={index} {...item} />
        ))}
      </List>
    </>
  );
};

export default Menu;
