import * as React from "react";
import Layout from "../components/Layout/Layout";
import ShippingAddresses from "../components/ShippingAddress/ShippingAddresses";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const ShippingAddressPage = () => {
  return (
    <>
      <Layout>
        <HeadingBold variant="h4">Ship-to Address</HeadingBold>
        <ShippingAddresses />
      </Layout>
    </>
  );
};
export default ShippingAddressPage;
