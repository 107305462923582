import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useAuth } from "../../../auth/AuthProvider";

const SignOutButton = () => {
  const auth = useAuth();

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {isBigScreen ? (
        <Button
          variant="text"
          color="inherit"
          aria-label="sign out"
          startIcon={<LogoutIcon />}
          onClick={() => auth.logout()}
        >
          Sign Out
        </Button>
      ) : (
        <Tooltip title="Sign Out">
          <LogoutIcon
            aria-label="sign out"
            onClick={() => auth.logout()}
            style={{ color: theme.palette.common.white }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default SignOutButton;
