import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import {
  ResponsiveBox,
  ResponsiveAppBar,
  ResponsiveNav,
  TemporaryDrawer,
  PermanentDrawer,
} from "../../UI/styled/styledDrawer";
import { StyledBox } from "../../UI/styled/styledLayout";
import { MobileButton, MobileIconButton } from "../../UI/styled/styledButtons";
import Menu from "./Menu";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HeaderStack from "../Stack/HeaderStack";
import HeaderStackWithCart from "../Stack/HeaderStackWithCart";
import HeaderLogo from "../../UI/HeaderLogo";

const ResponsiveDrawer: React.FC<{
  children: React.ReactNode;
  showCart: boolean;
}> = ({ children, showCart }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 240;

  const drawer = (
    <>
      <Toolbar>
        <Tooltip title="Collapse Sidebar" arrow placement="right">
          <MobileButton
            variant="text"
            onClick={handleDrawerToggle}
            startIcon={<CloseIcon />}
          >
            Close
          </MobileButton>
        </Tooltip>
      </Toolbar>
      <Divider />
      <Menu />
    </>
  );

  return (
    <StyledBox>
      <CssBaseline />
      <ResponsiveAppBar drawerwidth={drawerWidth} position="fixed">
        <Toolbar>
          <Tooltip title="Expand Sidebar">
            <MobileIconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </MobileIconButton>
          </Tooltip>
          <HeaderLogo />
          {!showCart ? <HeaderStack /> : <HeaderStackWithCart />}
        </Toolbar>
      </ResponsiveAppBar>
      <ResponsiveNav
        drawerwidth={drawerWidth}
        component="nav"
        aria-label="navbox folders"
      >
        <TemporaryDrawer
          variant="temporary"
          drawerwidth={drawerWidth}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </TemporaryDrawer>
        <PermanentDrawer variant="permanent" drawerwidth={drawerWidth} open>
          {drawer}
        </PermanentDrawer>
      </ResponsiveNav>
      <ResponsiveBox drawerwidth={drawerWidth} component="main">
        <Toolbar />
        <main>{children}</main>
      </ResponsiveBox>
    </StyledBox>
  );
};

export default ResponsiveDrawer;
