import { Button, Grid, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import {
  CartDTO,
  CartProductDTO,
  useCartService,
} from "../../../services/CartService";
import useErrorHandler from "../../../utils/errorHandling";
import { HeadingBold } from "../../UI/styled/styledFonts";
import validationSchema from "./OrderInfoValidationSchema";

export interface CartFormData {
  purchaseOrder: string;
  comments: string;
  products?: CartProductDTO[];
}

interface FormProps {
  cart: CartDTO | null;
}

function OrderInfoForm({ cart }: FormProps) {
  const cartService = useCartService();
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const formSubmissionHandler = async (values: CartFormData) => {
    try {
      if (orderId) {
        const response = await cartService.updateCart(orderId, values);
        if (response) {
          navigate(`/order/cart/${orderId}`);
        }
      }
    } catch (error) {
      console.error("Error updating cart data :", error);
      const err = error as AxiosError;
      handleError(err);
    }
  };

  const initData = {
    purchaseOrder: cart?.purchaseOrder || "",
    comments: cart?.comments || "",
    products: cart?.products || [],
  };

  const formik = useFormik({
    initialValues: initData,
    validationSchema: validationSchema,
    onSubmit: (values: CartFormData) => {
      formSubmissionHandler(values);
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <HeadingBold variant="h4">Edit Order Information</HeadingBold>
            <Grid item xs={12} md={5}>
              <TextField
                id="purchaseOrder"
                label="Purchase Order Number"
                margin="normal"
                fullWidth
                value={formik.values.purchaseOrder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="comments"
                label="Comments"
                margin="normal"
                fullWidth
                multiline
                rows={5}
                value={formik.values.comments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item>
                <Button type="submit" variant="contained" fullWidth>
                  Save Changes
                </Button>
              </Grid>
              <Grid item>
                <Button
                  to={`/order/cart/${cart?.orderId}`}
                  component={NavLink}
                  fullWidth
                  variant="text"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default OrderInfoForm;
