import { useAxios } from "../utils/hooks";

export interface Country extends CountryIdOnly {
  name: string;
}

export interface CountryIdOnly {
  id: string;
}

interface CountryService {
  getCountries: () => Promise<Country[]>;
  getCountryById: (countryId: string) => Promise<Country>;
}

export const useCountryService = (): CountryService => {
  const axios = useAxios("");
  return {
    getCountries: async () => {
      const value = await axios.current!.get("/api/country");
      return value.data as Country[];
    },
    getCountryById: async (countryId: string) => {
      const value = await axios.current!.get(`/api/country/${countryId}`);
      return value.data as Country;
    },
  };
};
