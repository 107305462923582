import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { StyledGrid } from "./styled/styledLayout";

function Loading() {
  return (
    <StyledGrid container>
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </StyledGrid>
  );
}

export default Loading;
