import React, { useCallback, useEffect, useState } from "react";
import {
  ShippingAddress,
  useShippingAddressService,
} from "../../services/ShippingAddressService";
import Loading from "../UI/Loading";
import { Pageable } from "../../types/common";
import SearchAndCreate from "../UI/SearchAndCreate";
import { rowsPerPage } from "../UI/TablePaginator";
import FilterContent from "../UI/FilterContent";
import TablePaginator from "../UI/TablePaginator";
import { getColumns } from "./ShippingAddressColumns";
import useErrorHandler from "../../utils/errorHandling";

const debounce = require("lodash.debounce");

export default function ShippingAddresses() {
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowCountState, setRowCountState] = useState(6378);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const { visibleColumns } = getColumns({
    shippingAddresses: shippingAddresses,
  });
  const handleError = useErrorHandler();

  const shippingAddressService = useShippingAddressService();

  const computeOffset = () => {
    return (
      (paginationModel.page - 1) * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize
    );
  };

  const loadShippingAddresses = (shippingAddresses: ShippingAddress[]) => {
    const offset = computeOffset();
    const estimatedCount = offset + shippingAddresses.length;
    if (estimatedCount > rowsPerPage) {
      setRowCountState(estimatedCount);
    } else if (shippingAddresses.length <= paginationModel.pageSize) {
      setRowCountState(estimatedCount);
    }
    setShippingAddresses(shippingAddresses.slice(0, rowsPerPage));
  };

  const loadAll = () => {
    const pageable: Pageable = {
      offset: computeOffset(),
      size: paginationModel.pageSize + 1,
    };
    setLoading(true);

    shippingAddressService
      .getShippingAddresses(pageable)
      .then(function (response: ShippingAddress[]) {
        loadShippingAddresses(response);
        console.debug("Get shipping addresses list:", response);
      })
      .catch((error) => {
        console.error("Error fetching shipping addresses:", error);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadFiltered = useCallback(
    debounce(
      (inputValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };
        setLoading(true);

        shippingAddressService
          .getFilteredShippingAddresses(inputValue, pageable)
          .then((shippingAddresses: ShippingAddress[]) => {
            loadShippingAddresses(shippingAddresses);
            console.debug(
              "Shipping addresses via search filter:",
              shippingAddresses
            );
          })
          .catch((error) => {
            console.error("Error fetching searched shipping addresses:", error);
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  useEffect(() => {
    if (searchTerm.length >= 3) {
      loadFiltered(searchTerm);
    } else if (searchTerm.length === 0) {
      loadAll();
    }
  }, [searchTerm, paginationModel.page]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSearchTerm(inputValue);
    setShippingAddresses([]);
    setLoading(true);
  };

  let content;

  if (searchTerm.length > 0 && searchTerm.length < 3) {
    content = (
      <FilterContent title="Enter 3 characters or more for filtering." />
    );
  } else if (loading) {
    content = <Loading />;
  } else {
    if (searchTerm.length >= 3) {
      content =
        shippingAddresses.length > 0 ? (
          <TablePaginator
            rows={shippingAddresses}
            columns={visibleColumns}
            rowCount={rowCountState}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <FilterContent title={`No matches for "${searchTerm}".`} />
        );
    } else if (searchTerm.length === 0) {
      content =
        shippingAddresses.length > 0 ? (
          <TablePaginator
            rows={shippingAddresses}
            columns={visibleColumns}
            rowCount={rowCountState}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <FilterContent title="No results available." />
        );
    }
  }

  return (
    <>
      <SearchAndCreate searchTerm={searchTerm} handleSearch={handleSearch} />
      {content}
    </>
  );
}
