import {
  Badge,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCartContext } from "../../../store/CartProvider";
import { NavLink, useParams } from "react-router-dom";
import { StyledCartButton } from "../../UI/styled/styledButtons";

const HeaderCartButton = () => {
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);
  const order = useCartContext().order;

  const { orderId } = useParams<{ orderId?: string }>();

  useEffect(() => {
    const totalItems = order?.products!.reduce((currentNumber, item) => {
      return currentNumber + item.quantity!;
    }, 0);
    setNumberOfCartItems(totalItems);
  }, [order.products]);

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Tooltip title="Cart Overview">
        {isBigScreen ? (
          <StyledCartButton
            variant="text"
            color="inherit"
            aria-label="show cart"
            startIcon={<ShoppingCartIcon />}
            to={`/order/cart/${orderId}`}
            component={NavLink}
          >
            Cart
            <Typography variant="inherit">({numberOfCartItems})</Typography>
          </StyledCartButton>
        ) : (
          <Badge
            color="secondary"
            badgeContent={numberOfCartItems}
            to={`/order/cart/${orderId}`}
            component={NavLink}
            showZero
          >
            <ShoppingCartIcon style={{ color: theme.palette.common.white }} />
          </Badge>
        )}
      </Tooltip>
    </>
  );
};

export default HeaderCartButton;
