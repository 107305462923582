import {
  Badge,
  Button,
  IconButton,
  ListItemButton,
  styled,
} from "@mui/material";

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
}));

export const StyledOrderButton = styled(Button)(({ theme }) => ({
  top: theme.spacing(3),
}));

export const StyledDrawerButton = styled(ListItemButton)(({ theme }) => ({
  "&.active": {
    backgroundColor: theme.palette.primary.main,
  },
})) as typeof ListItemButton;

export const StyledCartButton = styled(Button)({
  marginLeft: "auto",
}) as typeof Button;

export const StyledAccountButton = styled(Button)(({ theme }) => ({
  marginLeft: "auto",
  color: theme.palette.common.white,
  pointerEvents: "none",
})) as typeof Button;

export const StyledBadge = styled(Badge)({
  marginLeft: "auto",
});

export const MobileButton = styled(Button)(({ theme }) => ({
  color: "inherit",
  marginRight: theme.spacing(16),
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

export const MobileIconButton = styled(IconButton)(({ theme }) => ({
  color: "inherit",
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));
