import React from "react";
import { BillingAddress } from "../../../services/BillingAddressService";
import { useCartContext } from "../../../store/CartProvider";
import BodyText from "../../UI/BodyText";
import { StackAlignLeft } from "../../UI/styled/styledPrint";
import { HeadingBold } from "../../UI/styled/styledFonts";

function BillingAddressInfo() {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  const orderBillingAddress = order?.billingAddress as BillingAddress;

  return (
    <>
      <StackAlignLeft>
        <HeadingBold variant="h5">Billing Address</HeadingBold>
        <BodyText title="Name: " content={orderBillingAddress.name} />
        <BodyText
          title="Address: "
          content={
            orderBillingAddress.addressLine1 +
            " " +
            orderBillingAddress.addressLine2
          }
        />
        <BodyText title="Zipcode: " content={orderBillingAddress.zip} />
        <BodyText title="City: " content={orderBillingAddress.city} />
        <BodyText title="Country: " content={orderBillingAddress.country?.id} />
        <BodyText title="VAT #: " content={orderBillingAddress.vatId} />
      </StackAlignLeft>
    </>
  );
}

export default BillingAddressInfo;
