import { Country, CountryIdOnly } from "./CountryService";
import { BillingAddress, BillingAddressIdOnly } from "./BillingAddressService";
import { useAxios } from "../utils/hooks";
import { Pageable } from "../types/common";

export interface ShippingAddress extends ShippingAddressIdOnly {
  name: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  country: CountryIdOnly | Country | null;
  phone: string;
  email: string;
  description: string;
  billingAddress?: BillingAddressIdOnly | BillingAddress | null;
}

export interface ShippingAddressFull extends ShippingAddress {
  country: Country | null;
}

export interface ShippingAddressIdOnly {
  id?: string;
}

interface ShippingAddressService {
  getShippingAddresses: (pageable: Pageable) => Promise<ShippingAddress[]>;
  getShippingAddressesUnpaginated: () => Promise<ShippingAddress[]>;
  getShippingAddressById: (
    shippingAddressId: string
  ) => Promise<ShippingAddress>;
  createShippingAddress: (
    shippingAddress: ShippingAddress
  ) => Promise<ShippingAddress>;
  updateShippingAddress: (
    shippingAddress: ShippingAddress
  ) => Promise<ShippingAddress>;
  getFilteredShippingAddresses: (
    inputValue: string,
    pageable: Pageable
  ) => Promise<ShippingAddress[]>;
}

export const useShippingAddressService = (): ShippingAddressService => {
  const axios = useAxios("");
  return {
    getShippingAddresses: async (pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/address/shipping?start=${offset}&limit=${limit}&sort=name`
      );
      return value.data as ShippingAddress[];
    },
    getShippingAddressesUnpaginated: async () => {
      const value = await axios.current!.get("/api/address/shipping?start=0&limit=100&sort=name");
      return value.data as ShippingAddress[];
    },
    getShippingAddressById: async (shippingAddressId: string) => {
      const value = await axios.current!.get(
        `/api/address/shipping/${shippingAddressId}`
      );
      return value.data as ShippingAddress;
    },
    createShippingAddress: async (data: ShippingAddress) => {
      delete data.id;
      const value = await axios.current!.post("/api/address/shipping", data);
      return value.data as ShippingAddress;
    },
    updateShippingAddress: async (data: ShippingAddress) => {
      const value = await axios.current!.put(
        `/api/address/shipping/${data.id}`,
        data
      );
      return value.data as ShippingAddress;
    },
    getFilteredShippingAddresses: async (
      inputValue: string,
      pageable: Pageable
    ) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/address/shipping?start=${offset}&limit=${limit}&sort=name&search=${inputValue}`
      );

      return value.data as ShippingAddress[];
    },
  };
};
