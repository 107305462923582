import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import ChipStockLabel from "../UI/ChipStockLabel";
import { ProductSku } from "../../services/ProductService";

interface Props {
  productSkus: ProductSku[];
}

export const getColumns = ({ productSkus }: Props) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "code",
        headerName: "Model",
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        flex: 0.5,
        minWidth: 140,
      },
      {
        field: "name",
        headerName: "Description",
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        flex: 0.5,
        minWidth: 140,
      },
      {
        field: "stock",
        headerName: "Stock",
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        flex: 0.3,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => (
          <ChipStockLabel value={params.row.stock} />
        ),
      },
    ],
    [productSkus]
  );
  return { columns };
};
