import React from "react";
import { useCartContext } from "../../../store/CartProvider";
import BodyText from "../../UI/BodyText";
import ChipLabel, { OrderState, OrderStateDisplay } from "../../UI/ChipLabel";
import { StackAlignLeft } from "../../UI/styled/styledPrint";
import { HeadingBold } from "../../UI/styled/styledFonts";

interface Props {
  showComments: boolean;
}

function OrderInfo({ showComments }: Props) {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  return (
    <>
      <StackAlignLeft>
        <HeadingBold variant="h5">Order Information</HeadingBold>
        <BodyText title="Order #: " content={order?.orderNumber} />
        <BodyText title="P.O. Number: " content={order?.purchaseOrder} />
        <ChipLabel
          label={order?.orderState}
          backgroundColor={order?.orderState!}
        />
        {showComments && (
          <BodyText title="Comments: " content={order?.comments} />
        )}
      </StackAlignLeft>
    </>
  );
}

export default OrderInfo;
