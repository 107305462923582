import React, { useCallback, useEffect, useState } from "react";
import { Grid, SelectChangeEvent, TablePagination } from "@mui/material";
import ProductCard from "./ProductCard";
import { Product, useProductService } from "../../services/ProductService";
import { Pageable } from "../../types/common";
import Loading from "../UI/Loading";
import FilterContent from "../UI/FilterContent";
import { rowsPerPage } from "../UI/TablePaginator";
import SearchField from "../UI/SearchField";
import SelectProductGroup from "./SelectProductGroup";
import { useProductGroupService } from "../../services/ProductGroupService";
import useErrorHandler from "../../utils/errorHandling";
import FlexEndGrid from "../UI/FlexEndGrid";

const debounce = require("lodash.debounce");

const Products = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(6378);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const productService = useProductService();
  const handleError = useErrorHandler();

  const groupService = useProductGroupService();

  const computeOffset = () => {
    return (
      (paginationModel.page - 1) * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize
    );
  };

  const loadProducts = (products: Product[]) => {
    const offset = computeOffset();
    const estimatedCount = offset + products.length;
    if (estimatedCount > rowsPerPage) {
      setRowCountState(estimatedCount);
    } else if (products.length <= paginationModel.pageSize) {
      setRowCountState(estimatedCount);
    }
    setProducts(products.slice(0, rowsPerPage));
  };

  const loadAll = () => {
    const pageable: Pageable = {
      offset: computeOffset(),
      size: paginationModel.pageSize + 1,
    };
    setLoading(true);

    productService
      .getProducts(pageable)
      .then((response: Product[]) => {
        loadProducts(response);
        console.debug("Get products list:", products);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadFiltered = useCallback(
    debounce(
      (inputValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        setLoading(true);

        productService
          .getFilteredProducts(inputValue, pageable)
          .then((products: Product[]) => {
            loadProducts(products);
            console.debug("Products via search filter:", products);
          })
          .catch((error) => {
            console.error("Error fetching searched products:", error);
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  const loadFilteredWithGroup = useCallback(
    debounce(
      (selectValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        groupService
          .getFilteredProductsWithGroup(selectValue, pageable)
          .then((products: Product[]) => {
            loadProducts(products);
            console.debug("Products via the selected group filter:", products);
          })
          .catch((error) => {
            console.error(
              "Error fetching products of the selected group:",
              error
            );
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  const loadFilteredWithGroupAndSearch = useCallback(
    debounce(
      (selectValue: string, inputValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        productService
          .getFilteredProductsWithGroupAndSearch(
            selectValue,
            inputValue,
            pageable
          )
          .then((products: Product[]) => {
            loadProducts(products);
            console.debug(
              "Products via the selected group and search filter:",
              products
            );
          })
          .catch((error) => {
            console.error(
              "Error fetching products of the selected group and search filter:",
              error
            );
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  useEffect(() => {
    if (searchTerm.length >= 3 && selectedGroup === "") {
      loadFiltered(searchTerm);
    } else if (searchTerm.length === 0 && selectedGroup === "") {
      loadAll();
    } else if (selectedGroup && searchTerm.length === 0) {
      loadFilteredWithGroup(selectedGroup);
    } else if (selectedGroup && searchTerm.length >= 3) {
      loadFilteredWithGroupAndSearch(searchTerm, selectedGroup);
    }
  }, [searchTerm, selectedGroup, paginationModel.page]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSearchTerm(inputValue);
    setProducts([]);
    setLoading(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPaginationModel({
      page: newPage,
      pageSize: paginationModel.pageSize,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
  };

  function handleGroupChange(event: SelectChangeEvent<string>) {
    const selectValue = event.target.value;
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSelectedGroup(selectValue);
    setProducts([]);
    setLoading(true);
  }

  let content;

  const currentProductsList = products.map((product) => (
    <ProductCard
      id={product.id}
      key={product.id}
      imageUrl={product.imageUrl}
      description={product.description}
      model={product.model}
    />
  ));

  if (searchTerm.length > 0 && searchTerm.length < 3) {
    content = (
      <FilterContent title="Enter 3 characters or more for filtering." />
    );
  } else if (loading) {
    content = <Loading />;
  } else {
    if (searchTerm.length >= 3 && selectedGroup === "") {
      content =
        products.length > 0 ? (
          currentProductsList
        ) : (
          <FilterContent title={`No matches for "${searchTerm}".`} />
        );
    } else if (searchTerm.length === 0 || selectedGroup) {
      content =
        products.length > 0 ? (
          currentProductsList
        ) : (
          <FilterContent title="No results available." />
        );
    }
  }

  return (
    <>
      <FlexEndGrid>
        <SelectProductGroup
          selectedGroup={selectedGroup}
          handleGroupChange={handleGroupChange}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
      </FlexEndGrid>
      <Grid
        container
        spacing={{ xs: 2, md: 3, lg: 3 }}
        columns={{ xs: 5, sm: 8, md: 10, lg: 15 }}
      >
        {content}
      </Grid>
      <TablePagination
        component="div"
        count={rowCountState}
        page={paginationModel.page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
      />
    </>
  );
};

export default Products;
