import React, { useEffect } from "react";
import ResponsiveDrawer from "../Navbar/Menu/ResponsiveDrawer";
import { useNavigate } from "react-router";
import { useAuth } from "../../auth/AuthProvider";

const MainNavigationWithCart: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const nav = useNavigate();

  const auth = useAuth();

  useEffect(() => {
    if (auth.state == "anonymous") {
      nav("/login");
    }
  }, [auth]);

  return (
    <>
      <ResponsiveDrawer showCart={true}>{props.children}</ResponsiveDrawer>
    </>
  );
};

export default MainNavigationWithCart;
