import { Grid } from "@mui/material";
import React from "react";
import { ChildrenProps } from "../../types/common";

const FlexEndGrid: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
      flexGrow={1}
      marginBottom={3}
      marginTop={3}
    >
      {children}
    </Grid>
  );
};

export default FlexEndGrid;
