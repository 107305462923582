import { AxiosError } from "axios";
import { useNavigate } from "react-router";

const useErrorHandler = () => {
  const navigate = useNavigate();

  const handleError = (error: AxiosError) => {
    const responseURL = error.request.responseURL;
    const errorMessage = error.message;
    const errorTitle = responseURL
      ? `Error while fetching URL "${responseURL}"`
      : "Something went wrong";

    if (error.response?.status === 401) {
      navigate("/login");
    } else {
      navigate("/error", {
        state: {
          error: errorTitle,
          errorMessage: errorMessage,
        },
      });
    }
  };

  return handleError;
};

export default useErrorHandler;
