import { useEffect, useRef } from "react";
import type { AxiosInstance } from "axios";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";
import { useServerConfig } from "../config/ServerConfigProvider";

export const useAxios = (resourceUrl: string) => {
  const axiosInstance = useRef<AxiosInstance>();
  const { principal, state, logout } = useAuth();
  const serverConfig = useServerConfig();
  const initialized = state == "done";
  const kcToken = principal?.token;

  useEffect(() => {
    if (initialized && serverConfig) {
      axiosInstance.current = axios.create({
        baseURL: serverConfig.apiBaseUrl + resourceUrl,
        headers: {
          Authorization: initialized ? `Bearer ${kcToken}` : undefined,
        },
      });
      axiosInstance.current.interceptors.response.use(
        (response) => response,
        async (error) => {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // Do something with response error
          if (401 === error.response.status) {
            await logout();
            axiosInstance.current = undefined;
            return await Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        }
      );
    } else {
      axiosInstance.current = undefined;
    }

    return () => {
      axiosInstance.current = undefined;
    };
  }, [resourceUrl, kcToken, state]);

  return axiosInstance;
};
