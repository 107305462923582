import React, { useCallback, useEffect, useState } from "react";
import { OrderDTO, useOrderService } from "../../services/OrderService";
import Loading from "../UI/Loading";
import { Pageable } from "../../types/common";
import FilterContent from "../UI/FilterContent";
import TablePaginator, { rowsPerPage } from "../UI/TablePaginator";
import { getColumns } from "./OrderColumns";
import useErrorHandler from "../../utils/errorHandling";
import ModalTemplate from "../UI/ModalTemplate";
import { AxiosError } from "axios";
import { useCartService } from "../../services/CartService";
import { SelectChangeEvent } from "@mui/material";
import SearchCreateSelect from "../UI/SearchCreateSelect";

const debounce = require("lodash.debounce");

function Orders() {
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [rowCountState, setRowCountState] = useState(6378);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const {
    visibleColumns,
    open,
    handleClose,
    setOpen,
    selectedOrder,
    setSelectedOrder,
  } = getColumns({
    orders: orders,
  });

  const handleError = useErrorHandler();

  const orderService = useOrderService();
  const cartService = useCartService();

  const computeOffset = () => {
    return (
      (paginationModel.page - 1) * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize
    );
  };

  const loadOrders = (orders: OrderDTO[]) => {
    const offset = computeOffset();
    const estimatedCount = offset + orders.length;
    if (estimatedCount > rowsPerPage) {
      setRowCountState(estimatedCount);
    } else if (orders.length <= paginationModel.pageSize) {
      setRowCountState(estimatedCount);
    }
    setOrders(orders.slice(0, rowsPerPage));
  };

  const loadAll = () => {
    const pageable: Pageable = {
      offset: computeOffset(),
      size: paginationModel.pageSize + 1,
    };
    setLoading(true);

    orderService
      .getOrders(pageable)
      .then(function (response: OrderDTO[]) {
        loadOrders(response);
        console.debug("Get orders list:", response);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadFiltered = useCallback(
    debounce(
      (inputValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        orderService
          .getFilteredOrders(inputValue, pageable)
          .then((orders: OrderDTO[]) => {
            loadOrders(orders);
            console.debug("Orders via search filter:", orders);
          })
          .catch((error) => {
            console.error("Error fetching searched orders:", error);
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  const loadFilteredWithState = useCallback(
    debounce(
      (selectValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        orderService
          .getFilteredOrdersWithState(selectValue, pageable)
          .then((orders: OrderDTO[]) => {
            loadOrders(orders);
            console.debug("Orders via the selected state filter:", orders);
          })
          .catch((error) => {
            console.error(
              "Error fetching orders of the selected state:",
              error
            );
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  const loadFilteredWithStateAndSearch = useCallback(
    debounce(
      (selectValue: string, inputValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        orderService
          .getFilteredOrdersWithStateAndSearch(
            selectValue,
            inputValue,
            pageable
          )
          .then((orders: OrderDTO[]) => {
            loadOrders(orders);
            console.debug(
              "Orders via the selected state and search filter:",
              orders
            );
          })
          .catch((error) => {
            console.error(
              "Error fetching orders of the selected state and search filter:",
              error
            );
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  useEffect(() => {
    if (searchTerm.length >= 3 && selectedState === "") {
      loadFiltered(searchTerm);
    } else if (searchTerm.length === 0 && selectedState === "") {
      loadAll();
    } else if (selectedState && searchTerm.length === 0) {
      loadFilteredWithState(selectedState);
    } else if (selectedState && searchTerm.length >= 3) {
      loadFilteredWithStateAndSearch(searchTerm, selectedState);
    }
  }, [searchTerm, selectedState, paginationModel.page]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSearchTerm(inputValue);
    setOrders([]);
    setLoading(true);
  };

  const handleDeleteClick = async (id: string) => {
    try {
      if (id) {
        await cartService.deleteCart(id);
        console.log("Order deleted successfully.");
        setOrders((prevItems) => prevItems.filter((row) => row.id !== id));
        setSelectedOrder(null);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error deleting order:", error);
      const err = error as AxiosError;
      handleError(err);
    }
  };

  function handleStateChange(event: SelectChangeEvent<string>) {
    const selectValue = event.target.value;
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSelectedState(selectValue);
    setOrders([]);
    setLoading(true);
  }

  let content;

  if (searchTerm.length > 0 && searchTerm.length < 3) {
    content = (
      <FilterContent title="Enter 3 characters or more for filtering." />
    );
  } else if (loading) {
    content = <Loading />;
  } else {
    if (searchTerm.length >= 3) {
      content =
        orders.length > 0 ? (
          <TablePaginator
            rows={orders}
            columns={visibleColumns}
            rowCount={rowCountState}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <FilterContent title={`No matches for "${searchTerm}".`} />
        );
    } else if (searchTerm.length === 0) {
      content =
        orders.length > 0 ? (
          <TablePaginator
            rows={orders}
            columns={visibleColumns}
            rowCount={rowCountState}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <FilterContent title="No results available." />
        );
    }
  }

  return (
    <>
      <SearchCreateSelect
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        selectedState={selectedState}
        handleStateChange={handleStateChange}
      />
      {content}
      {open && (
        <ModalTemplate
          open={open}
          onClose={handleClose}
          onSubmit={() => handleDeleteClick(selectedOrder?.id!)}
          title="Confirm Delete"
          message="This order will be deleted immediately. You can't undo this action."
          primaryButton="Cancel"
          secondaryButton="Delete"
        />
      )}
    </>
  );
}
export default Orders;
