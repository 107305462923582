import { OAuth2Client } from "@badgateway/oauth2-client";
import { ServerConfig } from "../config/ServerConfigProvider";

export function buildClient(config: ServerConfig) {
  return new OAuth2Client({
    server: config.apiBaseUrl,
    clientId: config.oauthClientId,
    clientSecret: "thisaintnosecretatallandweknowitmate",
    // use proxy to avoid issues with cors when getting the token
    tokenEndpoint: config.baseUrl + "/oauth2/token",
    authorizationEndpoint: config.apiBaseUrl + "/oauth2/authorize",
  });
}
