import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import PrintButton from "../../UI/PrintButton";
import StackButtonsRow from "../../UI/StackButtonsRow";
import PrintableComponent from "./PrintableComponent";

function ReviewOrder() {
  return (
    <>
      <PrintableComponent />
      <StackButtonsRow>
        <PrintButton />
        <Button type="button" variant="text" to=".." component={NavLink}>
          Back
        </Button>
      </StackButtonsRow>
    </>
  );
}

export default ReviewOrder;
