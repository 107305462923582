import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { OrderState, OrderStateDisplay } from "../UI/ChipLabel";

interface SelectProps {
  selectedState: string | undefined;
  handleStateChange: (event: SelectChangeEvent<string>) => void;
}

function StatusSelect({ selectedState, handleStateChange }: SelectProps) {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <FormControl style={{ width: isBigScreen ? "18rem" : "9rem" }} size="small">
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        labelId="status-label"
        id="statusId"
        name="statusId"
        value={selectedState}
        label="Status"
        onChange={handleStateChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {(Object.keys(OrderState) as Array<keyof typeof OrderState>).map(
          (status) => (
            <MenuItem key={OrderState[status]} value={OrderState[status]}>
              {OrderStateDisplay[OrderState[status]]}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}

export default StatusSelect;
