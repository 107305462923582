import * as React from "react";
import Layout from "../components/Layout/Layout";
import BillingAddressForm from "../components/BillingAddress/BillingAddressForm";
import { useParams } from "react-router-dom";
import {
  BillingAddress,
  useBillingService,
} from "../services/BillingAddressService";
import { useEffect, useState } from "react";
import Loading from "../components/UI/Loading";

const EditBillingAddressPage = () => {
  const [billingAddress, setBillingAddress] = useState<BillingAddress | null>(
    null
  );

  const billingService = useBillingService();
  const { billingAddressId } = useParams<{ billingAddressId?: string }>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (billingAddressId) {
      billingService
        .getBillingAddressById(billingAddressId)
        .then(function (billing: BillingAddress) {
          setBillingAddress(billing);
        })
        .finally(function () {
          setLoading(false);
        });
    }
  }, [billingAddressId]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Layout>
          <BillingAddressForm billingAddress={billingAddress} />
        </Layout>
      )}
    </>
  );
};
export default EditBillingAddressPage;
