import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ProductGroup,
  useProductGroupService,
} from "../../services/ProductGroupService";
import useErrorHandler from "../../utils/errorHandling";

interface SelectProps {
  selectedGroup: string | undefined;
  handleGroupChange: (event: SelectChangeEvent<string>) => void;
}

function SelectProductGroup({ selectedGroup, handleGroupChange }: SelectProps) {
  const [groupes, setGroupes] = useState<ProductGroup[]>([]);
  const [, setLoading] = useState(false);

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleError = useErrorHandler();

  const productGroupFetcher = useProductGroupService();

  useEffect(() => {
    setLoading(true);

    productGroupFetcher
      .getProductGroups()
      .then(function (response: ProductGroup[]) {
        setGroupes(response);
      })
      .catch((error) => {
        console.error("Error fetching product groups:", error);
        handleError(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, []);

  return (
    <FormControl style={{ width: isBigScreen ? "18rem" : "9rem" }} size="small">
      <InputLabel id="group-label">Group</InputLabel>
      <Select
        labelId="group-label"
        id="groupId"
        name="groupId"
        value={selectedGroup}
        label="Group"
        onChange={handleGroupChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {groupes.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.instanceName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectProductGroup;
