import React from "react";
import { StyledGrid } from "./styled/styledLayout";
import { Heading } from "./styled/styledFonts";

function FilterContent({ title }: { title: string }) {
  return (
    <StyledGrid container>
      <Heading variant="h5">{title}</Heading>
    </StyledGrid>
  );
}

export default FilterContent;
