import {
  Button,
  TextField,
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  BillingAddress,
  useBillingService,
} from "../../services/BillingAddressService";
import {
  ShippingAddress,
  useShippingAddressService,
} from "../../services/ShippingAddressService";
import { Country, useCountryService } from "../../services/CountryService";
import { useFormik } from "formik";
import Loading from "../UI/Loading";
import { HeadingBold } from "../UI/styled/styledFonts";
import { Pageable } from "../../types/common";
import { rowsPerPage } from "../UI/TablePaginator";
import { AxiosError } from "axios";
import validationSchema from "./ShippingAddressValidationSchema";
import useErrorHandler from "../../utils/errorHandling";

export interface ShippingAddressFormData {
  id?: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  countryId: string | null;
  phone: string;
  email: string;
  description: string;
  parentId?: string | null;
}

interface FormProps {
  shippingAddress: ShippingAddress | null;
}

const ShippingAddressForm = ({ shippingAddress }: FormProps) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const countryFetcher = useCountryService();
  const billingAddressFetcher = useBillingService();
  const shippingAddressService = useShippingAddressService();
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  useEffect(() => {
    setLoading(true);

    countryFetcher
      .getCountries()
      .then(function (response: Country[]) {
        setCountries(response);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
        handleError(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, [shippingAddress]);

  useEffect(() => {
    const pageable: Pageable = {
      offset: 0,
      size: rowsPerPage,
    };
    setLoading(true);

    billingAddressFetcher
      .getBillingAddresses(pageable)
      .then(function (response: BillingAddress[]) {
        setBillingAddresses(response);
      })
      .catch((error) => {
        console.error("Error fetching billing addresses:", error);
        handleError(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, [shippingAddress]);

  const formSubmissionHandler = async (
    values: ShippingAddressFormData,
    isNew: boolean
  ) => {
    if (isNew) {
      try {
        const createdShippingAddress =
          await shippingAddressService.createShippingAddress(
            mapShippingAddressFromForm(values)
          );

        if (createdShippingAddress) {
          navigate("/shipping-address");
        }
      } catch (error) {
        console.error("Error creating shipping address:", error);
        const err = error as AxiosError;
        handleError(err);
      }
    } else {
      const updatedShippingAddressData: ShippingAddress =
        mapShippingAddressFromForm(values!);

      try {
        const response = await shippingAddressService.updateShippingAddress(
          updatedShippingAddressData
        );

        if (response) {
          navigate("/shipping-address");
        }
      } catch (error) {
        console.error("Error updating shipping address:", error);
        const err = error as AxiosError;
        handleError(err);
      }
    }
  };

  let initData;
  if (shippingAddress !== null) {
    initData = mapShippingAddressToForm(shippingAddress!);
  } else {
    initData = {
      name: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      city: "",
      countryId: "",
      phone: "",
      email: "",
      description: "",
      parentId: "",
    };
  }

  const formik = useFormik({
    initialValues: initData,
    validationSchema: validationSchema,
    onSubmit: (values: ShippingAddressFormData) => {
      formSubmissionHandler(values, shippingAddress === null);
    },
  });

  const isCreateMode = shippingAddress === null;

  const pageTitle = isCreateMode
    ? "Create New Shipping Address"
    : "Edit Shipping Address";
  const buttonTitle = isCreateMode ? "Create Entry" : "Save Changes";

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <HeadingBold variant="h4">{pageTitle}</HeadingBold>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="address-line-1"
                name="addressLine1"
                label="Address Line 1"
                margin="normal"
                fullWidth
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.addressLine1 &&
                  Boolean(formik.errors.addressLine1)
                }
                helperText={
                  formik.touched.addressLine1 && formik.errors.addressLine1
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="address-line-2"
                name="addressLine2"
                label="Address Line 2"
                margin="normal"
                fullWidth
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="zip"
                name="zip"
                label="Zipcode"
                margin="normal"
                fullWidth
                value={formik.values.zip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="city"
                name="city"
                label="City"
                margin="normal"
                fullWidth
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="country-label"
                  error={
                    formik.touched.countryId && Boolean(formik.errors.countryId)
                  }
                >
                  Country
                </InputLabel>
                <Select
                  id="countryId"
                  name="countryId"
                  labelId="country-label"
                  label="Country"
                  value={formik.values.countryId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.countryId && Boolean(formik.errors.countryId)
                  }
                >
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={true}>
                  {formik.touched.countryId && formik.errors.countryId}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="telephone"
                name="phone"
                label="Telephone"
                margin="normal"
                fullWidth
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="description"
                name="description"
                label="Description"
                margin="normal"
                fullWidth
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="parentId-label"
                  error={
                    formik.touched.parentId && Boolean(formik.errors.parentId)
                  }
                >
                  Billing Address
                </InputLabel>
                <Select
                  id="parentId"
                  name="parentId"
                  labelId="parentId-label"
                  label="Billing Address"
                  value={formik.values.parentId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.parentId && Boolean(formik.errors.parentId)
                  }
                >
                  {billingAddresses.map((billing) => (
                    <MenuItem key={billing.id} value={billing.id}>
                      {billing.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={true}>
                  {formik.touched.parentId && formik.errors.parentId}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item>
                <Button type="submit" variant="contained" fullWidth>
                  {buttonTitle}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  to="/shipping-address"
                  component={NavLink}
                  fullWidth
                  variant="text"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

function mapShippingAddressToForm(
  value: ShippingAddress
): ShippingAddressFormData {
  const { country, ...dataToLoad } = value;
  return {
    ...dataToLoad,
    countryId: country!.id,
  };
}

function mapShippingAddressFromForm(
  value: ShippingAddressFormData
): ShippingAddress {
  const { countryId, ...dataToSave } = value;
  return {
    country: { id: countryId! },
    ...dataToSave,
  };
}

export default ShippingAddressForm;
