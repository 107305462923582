import { Box, Grid, Stack, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  display: "flex",
});

export const BoxPositionAbsolute = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: "50rem",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #000",
  boxShadow: theme.shadows[24],
  padding: "3.25rem",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const StyledTotalBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  padding: "1rem",
  fontSize: "0.875rem",
  fontWeight: "bold",
});

export const StyledGrid = styled(Grid)(({ theme }) => ({
  wordWrap: "break-word",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginTop: theme.spacing(5),
  paddingRight: theme.spacing(10),
  paddingLeft: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

export const CustomStack = styled(Stack)({
  marginLeft: "auto",
});
