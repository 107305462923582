import * as React from "react";
import Layout from "../components/Layout/Layout";
import { useAuth } from "../auth/AuthProvider";
import { StyledGrid } from "../components/UI/styled/styledLayout";
import { Heading } from "../components/UI/styled/styledFonts";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const auth = useAuth();
  const nav = useNavigate()
  let element;

  if (auth.state === "done") {
    element = (
      <Heading variant="h4">You are logged in, continue to your screen</Heading>
    );
  } else if (auth.state === "anonymous") {
	  // redirect will be done by Layout
    element = <Heading variant="h4">Authenticating...</Heading>;
  }

  return (
    <>
      <Layout>
        <StyledGrid container>{element}</StyledGrid>
      </Layout>
    </>
  );
};
export default HomePage;
