import { Box, styled, TableCell, TableContainer } from "@mui/material";

export const HiddenCellForMobile = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("md")]: { display: "none" },
}));

export const ProductModalTableContainer = styled(TableContainer)(
  ({ theme }) => ({
    maxHeight: "20rem",
    overflowY: "auto",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxHeight: "15rem",
      marginTop: theme.spacing(2),
    },
  })
);

export const StyledGridBox = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  marginTop: theme.spacing(3),
}));
