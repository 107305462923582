import React, { useEffect } from "react";
import { useAuth, useAuthManipulator } from "../auth/AuthProvider";
import { StyledGrid } from "../components/UI/styled/styledLayout";
import { Heading } from "../components/UI/styled/styledFonts";
import { useServerConfig } from "../config/ServerConfigProvider";
import { useNavigate } from "react-router";

export default function LogoutPage() {
  const auth = useAuth();
  const authManipulator = useAuthManipulator();
  const nav = useNavigate();
  const serverConfig = useServerConfig();

  useEffect(() => {
    if (auth.state == "logging-out") {
      authManipulator.logoutFinished();
    }
  }, [auth.state, auth.principal]);

  if (!serverConfig) {
    return (
      <StyledGrid container>
        <Heading variant="h4">Signed out, redirecting...</Heading>
      </StyledGrid>
    );
  }

  if (auth.state == "done") {
    nav("/");
  } else if (auth.state == "logging-out") {
    let redirectUrl = serverConfig.baseUrl + "/login";
    window.location.assign(
      serverConfig.apiBaseUrl +
        `/logout-and-redirect?redirect-uri=${encodeURIComponent(redirectUrl)}`
    );
  }

  return (
    <StyledGrid container>
      <Heading variant="h4">Signed out, redirecting...</Heading>
    </StyledGrid>
  );
}
