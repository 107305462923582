import * as React from "react";
import Layout from "../components/Layout/Layout";
import BillingAddressForm from "../components/BillingAddress/BillingAddressForm";

const NewBillingAddressPage = () => {
  return (
    <>
      <Layout>
        <BillingAddressForm billingAddress={null} />
      </Layout>
    </>
  );
};
export default NewBillingAddressPage;
