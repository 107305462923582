import React from "react";
import CartProvider from "../../store/CartProvider";
import MainNavigation from "./MainNavigation";
import MainNavigationWithCart from "./MainNavigationWithCart";

const LayoutWithCart: React.FC<{
  children: React.ReactNode;
  showCart: boolean;
}> = (props) => {
  return (
    <>
      <CartProvider>
        {props.showCart && (
          <MainNavigationWithCart>{props.children}</MainNavigationWithCart>
        )}
        {!props.showCart && <MainNavigation>{props.children}</MainNavigation>}
      </CartProvider>
    </>
  );
};

export default LayoutWithCart;
