import { Typography, styled } from "@mui/material";

export const BoldTextLabel = styled("span")({ fontWeight: "bold" });

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
})); //when using, always add a specific variant, f.e. variant="h4"

export const HeadingBold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(3),
})); //when using, always add a specific variant, f.e. variant="h4"
