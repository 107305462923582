import { TableRow, TableCell, TextField } from "@mui/material";
import React from "react";
import { ProductSku } from "../../services/ProductService";

interface ProductQuantityProps {
  productSkus: ProductSku[];
  selectedQuantities: Record<string, number>;
  handleInputChange: (productSkuId: string, value: string) => void;
  rowId: string;
  rowCode: string;
  rowStock: string;
}

const ProductQuantityField: React.FC<ProductQuantityProps> = ({
  productSkus,
  selectedQuantities,
  handleInputChange,
  rowId,
  rowCode,
  rowStock,
}) => (
  <>
    {productSkus.length > 0 ? (
      <TextField
        id={`amount_${rowId}_${rowCode}`}
        name="qty"
        size="small"
        value={selectedQuantities[rowId]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(rowId, event.target.value)
        }
        InputProps={{
          inputProps: {
            min: 0,
            max: rowStock,
            style: { width: "3.5rem", height: "0.2rem" },
          },
        }}
      />
    ) : (
      <TableRow>
        <TableCell colSpan={4}>No products available</TableCell>
      </TableRow>
    )}
  </>
);

export default ProductQuantityField;
