import { Stack } from "@mui/material";
import React from "react";
import { ChildrenProps } from "../../types/common";

const StackButtonsWithPadding: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Stack direction="row" spacing={3} sx={{ mt: 3 }}>
      {children}
    </Stack>
  );
};

export default StackButtonsWithPadding;
