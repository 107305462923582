import { useAxios } from "../utils/hooks";

export interface User {
  username: string;
  locale?: string;
}
interface UserService {
  getUser: () => Promise<User>;
}

export const useUserService = (): UserService => {
  const axios = useAxios("/rest");
  return {
    getUser: async () => {
      const value = await axios.current!.get("/userInfo");
      return value.data as User;
    },
  };
};
