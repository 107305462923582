import { AppBar, Box, Drawer, styled } from "@mui/material";

interface WithDrawerWidth {
  drawerwidth: number;
}

export const ResponsiveBox = styled(Box)<WithDrawerWidth>(
  ({ drawerwidth, theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerwidth}px)`,
    },
  })
);

export const ResponsiveAppBar = styled(AppBar)<WithDrawerWidth>(
  ({ drawerwidth, theme }) => ({
    [theme.breakpoints.up("lg")]: {
      marginLeft: `${drawerwidth}px`,
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

export const ResponsiveNav = styled(Box)<WithDrawerWidth>(
  ({ drawerwidth, theme }) => ({
    width: `${drawerwidth}`,
    flexShrink: 0,
    [theme.breakpoints.up("lg")]: {
      marginLeft: `${drawerwidth}px`,
    },
  })
);

export const TemporaryDrawer = styled(Drawer)<WithDrawerWidth>(
  ({ drawerwidth, theme }) => ({
    display: "block",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerwidth,
    },
  })
);

export const PermanentDrawer = styled(Drawer)<WithDrawerWidth>(
  ({ drawerwidth, theme }) => ({
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerwidth,
    },
  })
);
