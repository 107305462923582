import { useAxios } from "../utils/hooks";
import { Pageable } from "../types/common";

export interface Product {
  id: string;
  model: string;
  description: string;
  groupId: string;
  groupName: String;
  imageUrl: string;
  skus: ProductSku[];
}

export interface ProductSku {
  id: string;
  code: string;
  name: string;
  size: string;
  stock: number;
}

interface ProductService {
  getProducts: (pageable: Pageable) => Promise<Product[]>;
  getProductSkus: (pageable: Pageable) => Promise<ProductSku[]>;
  getProductById: (id: string) => Promise<Product>;
  getFilteredProducts: (
    inputValue: string,
    pageable: Pageable
  ) => Promise<Product[]>;
  getFilteredProductSkus: (
    inputValue: string,
    pageable: Pageable
  ) => Promise<ProductSku[]>;
  getFilteredProductsWithGroupAndSearch: (
    inputValue: string,
    selectValue: string,
    pageable: Pageable
  ) => Promise<Product[]>;
}

export const useProductService = (): ProductService => {
  const axios = useAxios("");
  return {
    getProducts: async (pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/product?start=${offset}&limit=${limit}&sort=description`
      );
      return value.data as Product[];
    },
    getProductSkus: async (pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/sku?start=${offset}&limit=${limit}&sort=code`
      );
      return value.data as ProductSku[];
    },
    getProductById: async (id: string) => {
      const value = await axios.current!.get(`/api/product/${id}`);
      return value.data as Product;
    },
    getFilteredProducts: async (inputValue: string, pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/product?start=${offset}&limit=${limit}&sort=description&search=${inputValue}`
      );

      return value.data as Product[];
    },
    getFilteredProductSkus: async (inputValue: string, pageable: Pageable) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/sku?start=${offset}&limit=${limit}&sort=code&search=${inputValue}`
      );

      return value.data as ProductSku[];
    },
    getFilteredProductsWithGroupAndSearch: async (
      inputValue: string,
      selectValue: string,
      pageable: Pageable
    ) => {
      const offset = pageable.offset;
      const limit = pageable.size;

      const value = await axios.current!.get(
        `/api/product?start=${offset}&limit=${limit}&sort=description&search=${inputValue}&group=${selectValue}`
      );

      return value.data as Product[];
    },
  };
};
