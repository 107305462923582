import { useAxios } from "../utils/hooks";
import {
  ShippingAddressFull,
  ShippingAddressIdOnly,
  ShippingAddress,
} from "./ShippingAddressService";

export interface CartFull extends CartDTO {
  shippingAddress?: ShippingAddressFull | null;
}

export interface CartProductDTO {
  productSkuId: string;
  quantity: number;
  imageUrl: string;
}

export interface CartDTO {
  orderId?: string;
  purchaseOrder?: string;
  comments?: string;
  shippingAddress?: ShippingAddressIdOnly | ShippingAddress | null;
  products?: CartProductDTO[];
  notifications?: string[];
}

interface CartService {
  getCartById: (orderId: string) => Promise<CartDTO>;
  createCart: (order: CartDTO) => Promise<CartDTO>;
  updateCart: (orderId: string, data: CartDTO) => Promise<CartDTO>;
  submitCart: (orderId: string) => Promise<CartDTO>;
  deleteCart: (orderId: string) => Promise<CartDTO>;
}

export const useCartService = (): CartService => {
  const axios = useAxios("");
  return {
    getCartById: async (orderId: string) => {
      const value = await axios.current!.get(`/api/cart/${orderId}`);
      return value.data as CartDTO;
    },
    createCart: async (data: CartDTO) => {
      delete data.orderId;
      const value = await axios.current!.post("/api/cart", data);
      return value.data as CartDTO;
    },
    updateCart: async (orderId: string, data: CartDTO) => {
      const value = await axios.current!.put(`/api/cart/${orderId}`, data);
      return value.data as CartDTO;
    },
    submitCart: async (orderId: string) => {
      const value = await axios.current!.post(`/api/cart/${orderId}`);
      return value.data as CartDTO;
    },
    deleteCart: async (orderId: string) => {
      const value = await axios.current!.delete(`/api/cart/${orderId}`);
      return value.data as CartDTO;
    },
  };
};
