import * as React from "react";
import Orders from "../components/Orders/Orders";
import Layout from "../components/Layout/Layout";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const OrdersPage = () => {
  return (
    <>
      <Layout>
        <HeadingBold variant="h4">My Orders</HeadingBold>
        <Orders />
      </Layout>
    </>
  );
};
export default OrdersPage;
