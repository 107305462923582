import React, { useEffect } from "react";
import { Principal, useAuthManipulator } from "../auth/AuthProvider";
import { buildClient } from "../auth/oauth";
import { StyledGrid } from "../components/UI/styled/styledLayout";
import { Heading } from "../components/UI/styled/styledFonts";
import {useServerConfig} from "../config/ServerConfigProvider";

export default function LoginPage() {
  const authManipulator = useAuthManipulator();
  const serverConfig = useServerConfig();

  if (!serverConfig) {
    return (
        <StyledGrid container>
          <Heading variant="h4">Authenticating...</Heading>
        </StyledGrid>
    );
  }

  const redirectUri = serverConfig.baseUrl + "/login";

  const loginFinishedUri = serverConfig.baseUrl;

  const url = new URL(document.location.toString());
  const code = url.searchParams.get("code");

  console.log(url);

  const client = buildClient(serverConfig)

  useEffect(() => {
    if (code === null) {
      client.authorizationCode
        .getAuthorizeUri({
          // URL in the app that the user should get redirected to after authenticating
          redirectUri: redirectUri,
          // Optional string that can be sent along to the auth server. This value will
          // be sent along with the redirect back to the app verbatim.
          state: "some-string",
          scope: [],
        })
        .then((value) => {
          window.location.replace(value);
        });
    } else {
      client.authorizationCode
        .getTokenFromCodeRedirect(url, { redirectUri: redirectUri })
        .then((oauth2Token) => {
          console.log(oauth2Token);
          const principal: Principal = {
            token: oauth2Token.accessToken,
            refreshToken: oauth2Token.refreshToken,
          };
          authManipulator.loginFinished(principal);
          window.location.href = loginFinishedUri;
        });
    }
  }, [code]);

  return (
    <StyledGrid container>
      <Heading variant="h4">Authenticating...</Heading>
    </StyledGrid>
  );
}
