import * as React from "react";
import Layout from "../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ShippingAddressForm from "../components/ShippingAddress/ShippingAddressForm";
import {
  ShippingAddress,
  useShippingAddressService,
} from "../services/ShippingAddressService";
import Loading from "../components/UI/Loading";

const EditShippingAddressPage = () => {
  const [shippingAddress, setShippingAddress] =
    useState<ShippingAddress | null>(null);
  const useshippingAddressService = useShippingAddressService();
  const { shippingAddressId } = useParams<{ shippingAddressId?: string }>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (shippingAddressId) {
      useshippingAddressService
        .getShippingAddressById(shippingAddressId)
        .then(function (shippingAddress: ShippingAddress) {
          setShippingAddress(shippingAddress);
        })
        .finally(function () {
          setLoading(false);
        });
    }
  }, [shippingAddressId]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Layout>
          <ShippingAddressForm shippingAddress={shippingAddress} />
        </Layout>
      )}
    </>
  );
};
export default EditShippingAddressPage;
