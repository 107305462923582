import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import ShippingAddressInfo from "../../Orders/ReviewOrder/ShippingAddressInfo";
import BodyText from "../../UI/BodyText";
import { HeadingBold } from "../../UI/styled/styledFonts";
import { StackAlignLeft } from "../../UI/styled/styledPrint";
import EditIcon from "@mui/icons-material/Edit";
import { useCartContext } from "../../../store/CartProvider";
import { useNavigate } from "react-router";

function OrderInfoCart() {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  const navigate = useNavigate();

  const handleEditClick =
    (orderId: string | undefined | number) => async () => {
      if (orderId) {
        navigate(`/order/${orderId}/edit`);
      }
    };
  return (
    <>
      <Stack spacing={1} sx={{ ml: "1rem" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <HeadingBold variant="h5">Order Information</HeadingBold>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={handleEditClick(order.id)}
          >
            <EditIcon />
          </IconButton>
        </Stack>
        <StackAlignLeft>
          <BodyText title="P.O. Number: " content={order?.purchaseOrder} />
          <BodyText title="Comments: " content={order?.comments} />
        </StackAlignLeft>
        <ShippingAddressInfo />
      </Stack>
    </>
  );
}

export default OrderInfoCart;
