import React from "react";
import LayoutWithCart from "../components/Layout/LayoutWithCart";
import ReviewOrder from "../components/Orders/ReviewOrder/ReviewOrder";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const OrderDetailsPage = () => {
  return (
    <>
      <LayoutWithCart showCart={false}>
        <HeadingBold variant="h4">Order Details</HeadingBold>
        <ReviewOrder />
      </LayoutWithCart>
    </>
  );
};

export default OrderDetailsPage;
