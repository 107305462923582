import { AccountCircle } from "@mui/icons-material";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { User, useUserService } from "../../../services/UserService";
import {
  StyledAccountButton,
  StyledBadge,
} from "../../UI/styled/styledButtons";
import DoneIcon from "@mui/icons-material/Done";
import { useAlert } from "../../../store/AlertProvider";

const AccountButton = () => {
  const [user, setUser] = useState<User | null>(null);
  const { showAlert } = useAlert();

  const userFetcher = useUserService();

  useEffect(() => {
    userFetcher
      .getUser()
      .then(function (response: User) {
        setUser(response);
        console.debug("Get user:", response);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
        showAlert("Error fetching user.", { variant: "error" });
      });
  }, []);

  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {isBigScreen ? (
        <StyledAccountButton variant="text" startIcon={<AccountCircle />}>
          <Typography variant="inherit">{user?.username}</Typography>
        </StyledAccountButton>
      ) : (
        <StyledBadge
          color="secondary"
          badgeContent={<DoneIcon fontSize="inherit" />}
        >
          <AccountCircle />
        </StyledBadge>
      )}
    </>
  );
};

export default AccountButton;
