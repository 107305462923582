import { buildClient } from "./oauth";
import * as React from "react";
import { useContext, useState } from "react";
import { OAuth2Client } from "@badgateway/oauth2-client";
import { useServerConfig } from "../config/ServerConfigProvider";
import { Router } from "@remix-run/router"

interface Props {
  children: React.ReactNode;
  router: Router
}

type state = "anonymous" | "in-progress" | "done" | "logging-out";

export interface Principal {
  token: string;
  refreshToken: string | null;
}

export interface Authentication {
  state: state;
  principal?: Principal;
  login: (displayError: (err: string) => void) => Promise<void>;
  logout: () => Promise<void>;
}

export interface AuthenticationManipulator {
  loginFinished: (principal: Principal) => void;
  logoutFinished: () => void;
}

export interface AuthenticationContext {
  authentication: Authentication;
  manipulator: AuthenticationManipulator;
}

export const AuthContext = React.createContext<AuthenticationContext>(
  null as any
);
AuthContext.displayName = "Authentication";

function loadStorage(): Principal | undefined {
  let item = localStorage.getItem("outInovBr");
  if (item != null) {
    return JSON.parse(item) as Principal;
  } else {
    return undefined;
  }
}

export function setAuthStorage(it?: Principal): void {
  if (it) {
    localStorage.setItem("outInovBr", JSON.stringify(it));
  } else {
    localStorage.removeItem("outInovBr");
  }
}

export default function AuthProvider({ children, router }: Props) {
  const serverConfig = useServerConfig();
  const storage = loadStorage();
  const [state, setState] = useState<state>(() => storage ? "done" : "anonymous");
  const [principal, setPrincipal] = useState<Principal | undefined>(() => storage);
  const authManipulator = {
    loginStartProgress: () => setState("in-progress"),
    loginFinished: (it: Principal) => {
      setPrincipal(it);
      setState("done");
      setAuthStorage(it);
    },
    logoutFinished: () => {
      setPrincipal(undefined);
      setState("anonymous");
      setAuthStorage(undefined);
    },
  };

  const client = serverConfig ? buildClient(serverConfig) : undefined

  return (
    <AuthContext.Provider
      value={{
        authentication: {
          state: state,
          principal: principal,
          login: (displayError) => {
            return router.navigate("/order");
          },
          logout: () => {
            setState("logging-out");
            setPrincipal(undefined);
            setAuthStorage(undefined);
			return router.navigate("/logout");
          },
        },
        manipulator: authManipulator,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): Authentication {
  return useContext(AuthContext).authentication;
}

export function useAuthManipulator(): AuthenticationManipulator {
  return useContext(AuthContext).manipulator;
}
