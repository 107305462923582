import React from "react";
import { ShippingAddress } from "../../../services/ShippingAddressService";
import { useCartContext } from "../../../store/CartProvider";
import BodyText from "../../UI/BodyText";
import { StackAlignLeft } from "../../UI/styled/styledPrint";
import { HeadingBold } from "../../UI/styled/styledFonts";

function ShippingAddressInfo() {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  const orderShippingAddress = order?.shippingAddress as ShippingAddress;

  return (
    <>
      <StackAlignLeft>
        <HeadingBold variant="h5">Shipping Address</HeadingBold>
        <BodyText title="Name: " content={orderShippingAddress.name} />
        <BodyText
          title="Address: "
          content={
            orderShippingAddress.addressLine1 +
            " " +
            orderShippingAddress.addressLine2
          }
        />
        <BodyText title="Zipcode: " content={orderShippingAddress.zip} />
        <BodyText title="City: " content={orderShippingAddress.city} />
        <BodyText
          title="Country: "
          content={orderShippingAddress.country?.id}
        />
        <BodyText title="Phone #: " content={orderShippingAddress.phone} />
      </StackAlignLeft>
    </>
  );
}

export default ShippingAddressInfo;
