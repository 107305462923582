import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCartContext } from "../../../store/CartProvider";
import ReviewOrderTable from "./ReviewOrderTable";
import StackButtonsRow from "../../UI/StackButtonsRow";
import { AxiosError } from "axios";
import useErrorHandler from "../../../utils/errorHandling";
import { useCartService } from "../../../services/CartService";

import OrderInfoCart from "./OrderInfoCart";

const ReviewOrderForm = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const cartService = useCartService();

  const cartCtx = useCartContext();
  const order = cartCtx.order;

  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const hasItems = order?.products!.length! > 0;

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (orderId) {
        const submittedOrder = await cartService.submitCart(orderId);
        if (submittedOrder) {
          navigate("/order/");
        }
      }
    } catch (error) {
      console.error("Error submitting order:", error);
      const err = error as AxiosError;
      handleError(err);
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={submitHandler}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} md={8}>
              <ReviewOrderTable />
              <StackButtonsRow>
                <Button type="submit" variant="contained" disabled={!hasItems}>
                  Submit Order
                </Button>
                <Button
                  type="button"
                  variant="text"
                  to={`/order/${orderId}/products`}
                  component={NavLink}
                >
                  Back
                </Button>
              </StackButtonsRow>
            </Grid>
            <Grid item xs={12} md={4}>
              <OrderInfoCart />
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ReviewOrderForm;
