import React from "react";
import { OptionsObject, SnackbarProvider, useSnackbar } from "notistack";
import { ChildrenProps } from "../types/common";

type AlertSeverity = "error" | "warning" | "info" | "success";

interface AlertOptions {
  variant: AlertSeverity;
  autoHideDuration?: number;
}

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showAlert = (message: string, options: AlertOptions) => {
    const { variant, autoHideDuration } = options;
    const snackbarOptions: OptionsObject = {
      variant,
      autoHideDuration,
    };
    enqueueSnackbar(message, snackbarOptions);
  };

  return {
    showAlert,
  };
};

export const AlertProvider: React.FC<ChildrenProps> = ({ children }) => {
  return <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>;
};
