import React, { useCallback, useEffect, useState } from "react";
import {
  BillingAddress,
  useBillingService,
} from "../../services/BillingAddressService";
import Loading from "../UI/Loading";
import { Pageable } from "../../types/common";
import FilterContent from "../UI/FilterContent";
import { rowsPerPage } from "../UI/TablePaginator";
import SearchAndCreate from "../UI/SearchAndCreate";
import TablePaginator from "../UI/TablePaginator";
import { getColumns } from "./BillingAddressColumns";
import useErrorHandler from "../../utils/errorHandling";

const debounce = require("lodash.debounce");

const BillingAddresses = () => {
  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowCountState, setRowCountState] = useState(6378);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const { visibleColumns } = getColumns({ billingAddresses: billingAddresses });

  const handleError = useErrorHandler();

  const billingAddressService = useBillingService();

  const computeOffset = () => {
    return (
      (paginationModel.page - 1) * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize
    );
  };

  const loadBillingAddresses = (billingAddresses: BillingAddress[]) => {
    const offset = computeOffset();
    const estimatedCount = offset + billingAddresses.length;
    if (estimatedCount > rowsPerPage) {
      setRowCountState(estimatedCount);
    } else if (billingAddresses.length <= paginationModel.pageSize) {
      setRowCountState(estimatedCount);
    }
    setBillingAddresses(billingAddresses.slice(0, rowsPerPage));
  };

  const loadAll = () => {
    const pageable: Pageable = {
      offset: computeOffset(),
      size: paginationModel.pageSize + 1,
    };
    setLoading(true);

    billingAddressService
      .getBillingAddresses(pageable)
      .then(function (response: BillingAddress[]) {
        loadBillingAddresses(response);
        console.debug("Get billing addresses list:", response);
      })
      .catch((error) => {
        console.error("Error fetching billing addresses:", error);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadFiltered = useCallback(
    debounce(
      (inputValue: string) => {
        const pageable: Pageable = {
          offset: computeOffset(),
          size: paginationModel.pageSize + 1,
        };

        setLoading(true);

        billingAddressService
          .getFilteredBillingAddresses(inputValue, pageable)
          .then((billingAddresses: BillingAddress[]) => {
            loadBillingAddresses(billingAddresses);
            console.debug(
              "Billing addresses via search filter:",
              billingAddresses
            );
          })
          .catch((error) => {
            console.error("Error fetching searched billing addresses:", error);
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      500,
      { leading: false, trailing: true }
    ),
    [paginationModel.page]
  );

  useEffect(() => {
    if (searchTerm.length >= 3) {
      loadFiltered(searchTerm);
    } else if (searchTerm.length === 0) {
      loadAll();
    }
  }, [searchTerm, paginationModel.page]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSearchTerm(inputValue);
    setBillingAddresses([]);
    setLoading(true);
  };

  let content;

  if (searchTerm.length > 0 && searchTerm.length < 3) {
    content = (
      <FilterContent title="Enter 3 characters or more for filtering." />
    );
  } else if (loading) {
    content = <Loading />;
  } else {
    if (searchTerm.length >= 3) {
      content =
        billingAddresses.length > 0 ? (
          <TablePaginator
            rows={billingAddresses}
            columns={visibleColumns}
            rowCount={rowCountState}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <FilterContent title={`No matches for "${searchTerm}".`} />
        );
    } else if (searchTerm.length === 0) {
      content =
        billingAddresses.length > 0 ? (
          <TablePaginator
            rows={billingAddresses}
            columns={visibleColumns}
            rowCount={rowCountState}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <FilterContent title="No results available." />
        );
    }
  }

  return (
    <>
      <SearchAndCreate
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        rows={billingAddresses}
      />
      {content}
    </>
  );
};

export default BillingAddresses;
