import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { OrderDTO } from "../../services/OrderService";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ChipLabel, { OrderState, OrderStateDisplay } from "../UI/ChipLabel";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface Props {
  orders: OrderDTO[];
}

export const getColumns = ({ orders }: Props) => {
  const dateValueFormatter = (params: GridValueFormatterParams) =>
    params.value ? dayjs(params.value).format("MM/DD/YYYY") : "Invalid Date";
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderDTO | null>(null);

  const handleOpen = (order: OrderDTO) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setOpen(false);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "orderNumber",
        headerName: "Order #",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "orderState",
        headerName: "Status",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <ChipLabel
            label={OrderStateDisplay[params.row.orderState as OrderState]}
            backgroundColor={params.row.orderState}
          />
        ),
      },
      {
        field: "purchaseOrder",
        headerName: "Purchase Order",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "createdDate",
        headerName: "Order Date",
        type: "date",
        flex: 0.2,
        minWidth: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        valueFormatter: dateValueFormatter,
      },
      {
        field: "comments",
        headerName: "Comments",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
      {
        field: "shippingAddress",
        headerName: "Consignee",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.shippingAddress?.name;
        },
      },
      {
        field: "shippingAddress.city",
        headerName: "City",
        flex: 0.2,
        minWidth: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.shippingAddress?.city;
        },
      },
      {
        field: "shippingAddress.country",
        headerName: "Country",
        flex: 0.3,
        minWidth: 120,
        resizable: false,
        sortable: false,
        filterable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.shippingAddress?.country.name;
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        sortable: false,
        filterable: false,
        resizable: false,
        cellClassName: "actions",
        renderCell: ({ row }) => {
          return formattedLink(row);
        },
      },
    ],
    [orders]
  );

  const formattedLink = (order: OrderDTO) => {
    if (order.orderState === "OPEN") {
      return (
        <>
          <IconButton
            size="small"
            aria-label="edit"
            component={Link}
            to={`/order/${order.id}/products`}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => handleOpen(order)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <IconButton
            size="small"
            aria-label="show order detail"
            component={Link}
            to={`/order/detail/${order.id}`}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      );
    }
  };

  const hiddenColumns: string[] = [
    "purchaseOrder",
    "createdDate",
    "comments",
    "country",
    "shippingAddress",
    "shippingAddress.city",
    "shippingAddress.country",
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const visibleColumns = isSmallScreen
    ? columns.filter((column) => !hiddenColumns.includes(column.field))
    : columns;

  return {
    visibleColumns,
    open,
    handleClose,
    setOpen,
    selectedOrder,
    setSelectedOrder,
  };
};
