import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ShippingAddressPage from "./pages/ShippingAddressPage";
import OrdersPage from "./pages/OrdersPage";
import NewOrderPage from "./pages/NewOrderPage";
import ProductsPage from "./pages/ProductsPage";
import BillingAddressesPage from "./pages/BillingAddressesPage";
import StockPage from "./pages/StockPage";
import CheckoutPage from "./pages/CheckoutPage";
import LoginPage from "./pages/LoginPage";
import AuthProvider from "./auth/AuthProvider";
import HomePage from "./pages/HomePage";
import { CssBaseline } from "@mui/material";
import NewBillingAddressPage from "./pages/NewBillingAddressPage";
import EditBillingAddressPage from "./pages/EditBillingAddressPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import NewShippingAddressPage from "./pages/NewShippingAddressPage";
import EditShippingAddressPage from "./pages/EditShippingAddressPage";
import ServerConfigProvider from "./config/ServerConfigProvider";
import LogoutPage from "./pages/LogoutPage";
import ErrorPage from "./pages/ErrorPage";
import { AlertProvider } from "./store/AlertProvider";
import EditOrderInfoPage from "./pages/EditOrderInfoPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/order",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <OrdersPage />,
      },
      {
        path: "cart/:orderId",
        element: <CheckoutPage />,
      },
      {
        path: "detail/:orderId",
        element: <OrderDetailsPage />,
      },
      {
        path: "new",
        element: <NewOrderPage />,
      },
      {
        path: ":orderId/products",
        element: <ProductsPage />,
      },
      {
        path: ":orderId/edit",
        element: <EditOrderInfoPage />,
      },
    ],
  },
  {
    path: "/billing-address",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <BillingAddressesPage />,
      },
      {
        path: "new",
        element: <NewBillingAddressPage />,
      },
      {
        path: ":billingAddressId/edit",
        element: <EditBillingAddressPage />,
      },
    ],
  },
  {
    path: "/shipping-address",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ShippingAddressPage />,
      },
      {
        path: "new",
        element: <NewShippingAddressPage />,
      },
      {
        path: ":shippingAddressId/edit",
        element: <EditShippingAddressPage />,
      },
    ],
  },
  {
    path: "/stock",
    element: <StockPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
]);

export default function Router() {
  return (
    <ServerConfigProvider>
      <AuthProvider router={router}>
        <AlertProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </AlertProvider>
      </AuthProvider>
    </ServerConfigProvider>
  );
}
