import React from "react";
import HeaderCartButton from "../Cart/HeaderCartButton";
import AccountButton from "../Menu/AccountButton";
import SignOutButton from "../Menu/SignOutButton";
import { CustomStack } from "../../UI/styled/styledLayout";

const HeaderStackWithCart = () => {
  return (
    <CustomStack direction="row" spacing={3}>
      <HeaderCartButton />
      <AccountButton />
      <SignOutButton />
    </CustomStack>
  );
};

export default HeaderStackWithCart;
