import React from "react";
import ReviewOrderForm from "../components/Navbar/Cart/ReviewOrderForm";
import LayoutWithCart from "../components/Layout/LayoutWithCart";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const CheckoutPage = () => {
  return (
    <>
      <LayoutWithCart showCart={true}>
        <HeadingBold variant="h4">Checkout</HeadingBold>
        <ReviewOrderForm />
      </LayoutWithCart>
    </>
  );
};

export default CheckoutPage;
