import * as React from "react";
import Layout from "../components/Layout/Layout";
import BillingAddresses from "../components/BillingAddress/BillingAddresses";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const BillingAddressesPage = () => {
  return (
    <>
      <Layout>
        <HeadingBold variant="h4">Bill-to Address</HeadingBold>
        <BillingAddresses />
      </Layout>
    </>
  );
};
export default BillingAddressesPage;
