import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string().trim().required("Name is required"),
  description: yup.string().trim(),
  addressLine1: yup.string().trim().required("Address Line 1 is required"),
  addressLine2: yup.string().trim(),
  zip: yup.string().trim().required("Zipcode is required"),
  city: yup.string().trim().required("City is required"),
  countryId: yup.string().required("Country is required"),
  phone: yup.string().trim().required("Telephone is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  vatId: yup.string().trim().required("VAT ID is required"),
});

export default validationSchema;
