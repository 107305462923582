import React from "react";
import AccountButton from "../Menu/AccountButton";
import SignOutButton from "../Menu/SignOutButton";
import { CustomStack } from "../../UI/styled/styledLayout";

const HeaderStack = () => {
  return (
    <CustomStack direction="row" spacing={3}>
      <AccountButton />
      <SignOutButton />
    </CustomStack>
  );
};

export default HeaderStack;
