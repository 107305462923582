import React, { useState } from "react";
import { Grid, Typography, CardActions, Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProductsModal from "./ProductsModal";
import dummyPicture from "../../assets/dummyPicture.png";
import {
  StyledProductCardContent,
  StyledProductCard,
  StyledProductCardMedia,
} from "../UI/styled/styledMedia";

interface ProductProps {
  id: string;
  description: string;
  model: string;
  imageUrl: string;
}

const ProductCard: React.FC<ProductProps> = ({
  id,
  description,
  imageUrl,
  model,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid item xs={8} sm={4} md={3} key={id}>
      <StyledProductCard>
        <StyledProductCardMedia
          src={imageUrl || dummyPicture}
          alt={description}
        />
        <StyledProductCardContent>
          <Typography variant="h6" noWrap>
            {description}
          </Typography>
          <Typography variant="body1">{model}</Typography>
        </StyledProductCardContent>
        <CardActions>
          <Button
            size="large"
            variant="contained"
            aria-label="open modal"
            endIcon={<ChevronRightIcon />}
            onClick={handleOpen}
          >
            Place Order
          </Button>
          {open && <ProductsModal id={id} open={open} onClose={handleClose} />}
        </CardActions>
      </StyledProductCard>
    </Grid>
  );
};

export default ProductCard;
