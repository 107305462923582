import * as React from "react";
import Layout from "../components/Layout/Layout";
import Stock from "../components/Stock/Stock";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const StockPage = () => {
  return (
    <>
      <Layout>
        <HeadingBold variant="h4">Stock</HeadingBold>
        <Stock />
      </Layout>
    </>
  );
};
export default StockPage;
