import { Button } from "@mui/material";
import React, { useCallback, useRef } from "react";
import ReactToPrint from "react-to-print";
import PrintableComponent from "../Orders/ReviewOrder/PrintableComponent";

const PrintButton: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button variant="contained" color="primary">
        Print
      </Button>
    );
  }, []);
  return (
    <>
      <ReactToPrint
        content={reactToPrintContent}
        trigger={reactToPrintTrigger}
      />

      <div style={{ display: "none" }}>
        <PrintableComponent ref={componentRef} />
      </div>
    </>
  );
};

export default PrintButton;
