import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderDTO, OrderFull, useOrderService } from "../services/OrderService";
import useErrorHandler from "../utils/errorHandling";

export type CartContent = {
  order: OrderDTO | undefined;
  setOrder: (order: OrderDTO) => void;
  getOrder: () => OrderDTO | undefined;
};

const CartContext = React.createContext<CartContent>(null as any);

interface Props {
  children: React.ReactNode;
}

const CartProvider: React.FC<Props> = ({ children }) => {
  const [order, setOrder] = useState<OrderDTO>();

  const { orderId } = useParams<{ orderId: string }>();

  const handleError = useErrorHandler();

  const orderService = useOrderService();
  useEffect(() => {
    if (orderId) {
      orderService
        .getOrderById(orderId)
        .then((order) => {
          setOrder(order);
        })
        .catch((error) => {
          console.error(error);
          handleError(error);
        });
    }
  }, [orderId]);

  const cartContextValue: CartContent = {
    order: order,
    setOrder: (order: OrderDTO) => setOrder(order),
    getOrder: () => order,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      {order && children}
    </CartContext.Provider>
  );
};

interface CartContextHandler {
  order: OrderFull;
  reload(): void;
}

export function useCartContext(): CartContextHandler {
  const ctx = useContext(CartContext);
  const orderService = useOrderService();
  return {
    order: ctx.order! as OrderFull,
    reload: function () {
      orderService.getOrderById(ctx.order!.id!).then((order) => {
        ctx.setOrder(order);
      });
    },
  };
}

export default CartProvider;
