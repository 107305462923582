import * as React from "react";
import Layout from "../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../components/UI/Loading";
import OrderInfoForm from "../components/Navbar/Cart/OrderInfoForm";
import { CartDTO, useCartService } from "../services/CartService";

const EditOrderInfoPage = () => {
  const [cart, setCart] = useState<CartDTO | null>(null);
  const [loading, setLoading] = useState(false);

  const cartService = useCartService();
  const { orderId } = useParams<{ orderId?: string }>();

  useEffect(() => {
    setLoading(true);

    if (orderId) {
      cartService
        .getCartById(orderId)
        .then(function (cart: CartDTO) {
          setCart(cart);
        })
        .finally(function () {
          setLoading(false);
        });
    }
  }, [orderId]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Layout>
          <OrderInfoForm cart={cart} />
        </Layout>
      )}
    </>
  );
};
export default EditOrderInfoPage;
