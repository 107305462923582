import React from "react";
import { useCartContext } from "../../store/CartProvider";
import { StyledTotalBox } from "./styled/styledLayout";

function TotalAmountPagination() {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  const calculateTotalQuantity = () => {
    if (!order?.products) {
      return 0;
    }

    let sum = 0;
    for (const item of order?.products!) {
      sum += item.quantity!;
    }
    return sum;
  };

  return <StyledTotalBox>Total: {calculateTotalQuantity()}</StyledTotalBox>;
}

export default TotalAmountPagination;
