import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  ShippingAddress,
  useShippingAddressService,
} from "../../services/ShippingAddressService";
import Loading from "../UI/Loading";
import { AxiosError } from "axios";
import validationSchema from "./OrderValidationSchema";
import useErrorHandler from "../../utils/errorHandling";
import { CartDTO, useCartService } from "../../services/CartService";

export interface CartFormData {
  id?: string;
  purchaseOrder: string;
  comments: string;
  shippingAddressId: string | null;
}

interface FormProps {
  order: CartDTO | null;
}

const NewOrderForm = ({ order }: FormProps) => {
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const shippingAddressFetcher = useShippingAddressService();
  const cartService = useCartService();
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  useEffect(() => {
    setLoading(true);

    shippingAddressFetcher
      .getShippingAddressesUnpaginated()
      .then(function (response: ShippingAddress[]) {
        setShippingAddresses(response);
      })
      .catch((error) => {
        console.error("Error fetching shipping addresses:", error);
        handleError(error);
      })
      .finally(function () {
        setLoading(false);
      });
  }, [order]);

  const formSubmissionHandler = async (
    values: CartFormData,
    isNew: boolean
  ) => {
    if (isNew) {
      try {
        const createdOrder = await cartService.createCart(
          mapOrderFromForm(values)
        );

        if (createdOrder) {
          navigate(`/order/${createdOrder.orderId}/products`);
        }
      } catch (error) {
        console.error("Error creating order:", error);
        const err = error as AxiosError;
        handleError(err);
      }
    }
  };

  const initData = {
    shippingAddressId: "",
    purchaseOrder: "",
    comments: "",
  };

  const formik = useFormik({
    initialValues: initData,
    validationSchema: validationSchema,
    onSubmit: (values: CartFormData) => {
      formSubmissionHandler(values, order === null);
    },
  });
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="shippingAddress-label"
                  error={
                    formik.touched.shippingAddressId &&
                    Boolean(formik.errors.shippingAddressId)
                  }
                >
                  Shipping Address
                </InputLabel>
                <Select
                  labelId="shippingAddress-label"
                  id="shippingAddressId"
                  name="shippingAddressId"
                  value={formik.values.shippingAddressId}
                  label="Consignee"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.shippingAddressId &&
                    Boolean(formik.errors.shippingAddressId)
                  }
                >
                  {shippingAddresses.map((shippingAddress) => (
                    <MenuItem
                      key={shippingAddress.id}
                      value={shippingAddress.id}
                    >
                      {shippingAddress.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={true}>
                  {formik.touched.shippingAddressId &&
                    formik.errors.shippingAddressId}
                </FormHelperText>
              </FormControl>
              <TextField
                margin="normal"
                fullWidth
                id="purchaseOrder"
                value={formik.values.purchaseOrder}
                label="Purchase Order Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                id="comments"
                label="Comments"
                margin="normal"
                fullWidth
                multiline
                rows={5}
                value={formik.values.comments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                Continue On
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

function mapOrderFromForm(value: CartFormData): CartDTO {
  const { ...dataToSave } = value;
  return {
    ...dataToSave,
  };
}

export default NewOrderForm;
