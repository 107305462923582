import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useLocation } from "react-router-dom";
import { Item } from "./MenuItem";
import theme from "../../../theme";
import { ListItemButton } from "@mui/material";

const MenuItemComponent: React.FC<Item> = ({ link, icon: Icon, name }) => {
  const location = useLocation();
  return (
    <ListItemButton
      to={link}
      style={{
        backgroundColor:
          location.pathname === link
            ? theme.palette.primary.main
            : "transparent",
      }}
      component={link ? NavLink : "div"}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItemButton>
  );
};

export default MenuItemComponent;
