import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  message: string;
  title: string;
  primaryButton: string;
  secondaryButton: string;
}
const ModalTemplate = (props: Props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{props.primaryButton}</Button>
          <Button
            variant="contained"
            color="error"
            onClick={props.onSubmit}
            autoFocus
          >
            {props.secondaryButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalTemplate;
