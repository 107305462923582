import { Stack } from "@mui/material";
import React from "react";
import { ChildrenProps } from "../../types/common";

const StyledStack: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 3, marginTop: 3 }}
    >
      {children}
    </Stack>
  );
};

export default StyledStack;
