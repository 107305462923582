import { Card, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { ShippingAddress } from "../../../services/ShippingAddressService";
import { useCartContext } from "../../../store/CartProvider";
import BillingAddressInfo from "./BillingAddressInfo";
import OrderInfo from "./OrderInfo";
import ShippingAddressInfo from "./ShippingAddressInfo";
import { PrintableDiv, StyledStackPrint } from "../../UI/styled/styledPrint";
import BodyText from "../../UI/BodyText";
import OrderItemsTable from "./OrderItemsTable";

const PrintableComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
  const cartCtx = useCartContext();
  const order = cartCtx.order;

  const orderShippingAddress = order?.shippingAddress as ShippingAddress;
  return (
    <PrintableDiv ref={ref}>
      <Stack spacing={{ xs: 2, md: 3, lg: 3 }}>
        <Card variant="outlined">
          <CardContent>
            <StyledStackPrint>
              <OrderInfo showComments={false} />
              <ShippingAddressInfo />
              <BillingAddressInfo />
            </StyledStackPrint>
            <Stack direction="row" alignItems="flex-start">
              <BodyText
                title="Comments: "
                content={orderShippingAddress.description}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <OrderItemsTable />
          </CardContent>
        </Card>
      </Stack>
    </PrintableDiv>
  );
});

export default PrintableComponent;
