import * as React from "react";
import NewOrderForm from "../components/Orders/NewOrderForm";
import Layout from "../components/Layout/Layout";
import { HeadingBold } from "../components/UI/styled/styledFonts";

const NewOrderPage = () => {
  return (
    <>
      <Layout>
        <HeadingBold variant="h4">At Once Order</HeadingBold>
        <NewOrderForm order={null} />
      </Layout>
    </>
  );
};
export default NewOrderPage;
