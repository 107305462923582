import { TableContainer } from "@mui/material";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import React from "react";
import { ProductSku } from "../../services/ProductService";

interface Props {
  rows: ProductSku[];
  columns: GridColDef[];
  loading: boolean;
}

const ProductModalTable: React.FC<Props> = ({ rows, columns, loading }) => (
  <TableContainer style={{ width: "100%", height: "auto" }}>
    <DataGrid
      rows={rows}
      columns={columns}
      rowHeight={36}
      loading={loading}
      hideFooter
      sx={(theme) => ({
        [`.${gridClasses.main}`]: {
          overflow: "unset",
        },
        [`.${gridClasses.columnHeaders}`]: {
          position: "sticky",
          top: -1,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
        },
      })}
    />
  </TableContainer>
);

export default ProductModalTable;
