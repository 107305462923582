import { Chip } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  label: ReactNode;
  backgroundColor: string | null;
}

export enum OrderState {
  OPEN = "OPEN",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  SHIPPED = "SHIPPED",
}

export const OrderStateDisplay: { [key in OrderState]: string } = {
  [OrderState.OPEN]: "Open",
  [OrderState.AWAITING_APPROVAL]: "Awaiting Approval",
  [OrderState.CONFIRMED]: "Confirmed",
  [OrderState.CANCELLED]: "Cancelled",
  [OrderState.SHIPPED]: "Shipped",
};

const ChipLabel: React.FC<Props> = ({
  backgroundColor: value,
  label: content,
}) => {
  const getColorForValue = (value: string | null): string => {
    if (value === OrderState.OPEN) {
      return "#42a5f5";
    } else if (value === OrderState.AWAITING_APPROVAL) {
      return "#ff9800";
    } else if (value === OrderState.CONFIRMED) {
      return "#4caf50";
    } else if (value === OrderState.CANCELLED) {
      return "#f44336";
    } else {
      return "grey";
    }
  };
  return (
    <Chip
      size="small"
      label={content}
      style={{
        backgroundColor: getColorForValue(value),
        fontSize: "inherit",
      }}
    />
  );
};

export default ChipLabel;
