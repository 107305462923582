import { Divider } from "@mui/material";
import React from "react";
import MenuItemComponent from "./MenuItemComponent";

export interface Item {
  icon: React.ElementType;
  name: string;
  link: string;
}

const DynamicListItem: React.FC<Item> = ({ icon: Icon, name, link }) => {
  return (
    <>
      <MenuItemComponent link={link} icon={Icon} name={name} />
      <Divider />
    </>
  );
};

export default DynamicListItem;
